import { FC, useCallback, useEffect, useState } from "react";
import { Alert, Select, Space, notification, Form, Button } from "antd";
import blockipAPI from "@/api/apis/blockip";
import CheckButtonP from "@/components/checkButton";
import FormItem from "antd/es/form/FormItem";
// interface IpWhitelistProps {
//   value: number;
//   label: string;
//   key: number;
// }

const IpWhitelist: FC = () => {
  const [oldIpList, setOldIpList] = useState<string[]>([]);
  const [showIpList, setShowIpList] = useState(false);
  const [isReq, setIsReq] = useState(false);
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const handleGetIpblockStatus = useCallback(async () => {
    const status = await blockipAPI.blockipStatus();
    setShowIpList(status);
    setIsReq(status);
    form.setFieldValue("status", status ? "enable" : "disable");
  }, [setIsReq, setShowIpList, form]);

  const getIpWihteListData = useCallback(async () => {
    try {
      const res = await blockipAPI.blockipList({ page: 1, pageSize: 99999 });
      if (Array.isArray(res?.data)) {
        const _res = [...res?.data];
        const ipList = _res.map((item) => {
          return item.ip;
        });
        setOldIpList(ipList);
        form.setFieldValue("ips", ipList);
        // setInputValue(ids);
        // setIpList(ipList);
        // const _oldIds = _res.map((item) => item.id);
        // setOldIdIpList(_oldIds);
        // const _oldIps = _res.map((item) => item.ip);
        // setOldIpList(_oldIps);
      }
    } catch (error) {
      console.error(error);
    }
  }, [form]);
  useEffect(() => {
    handleGetIpblockStatus();
  }, [handleGetIpblockStatus]);
  useEffect(() => {
    if (showIpList) {
      getIpWihteListData();
    }
  }, [getIpWihteListData, showIpList]);
  const handleSubmit = useCallback(
    async (data: any) => {
      const status = data.status;
      const isStatus = status === "enable" ? true : false;
      if (isStatus && data.ips) {
        try {
          const ips = [...data.ips];
          const oldIpSet = new Set(oldIpList);
          const createIpList = ips.filter(
            (item: string) => !oldIpSet.has(item)
          );
          const newIpSet = new Set(ips);
          const deleteIpList = oldIpList.filter(
            (item: string) => !newIpSet.has(item)
          );

          await Promise.all([
            blockipAPI.blockipBatchCreate(createIpList),
            blockipAPI.blockipBatchDelete(deleteIpList),
          ]);
          if (isReq === isStatus) {
            api.success({
              message: "Save successful!",
            });
          }

          // if (createIpList.length > 0 && deleteIpList.length > 0) {
          //   await Promise.all([
          //     blockipAPI.blockipBatchCreate(createIpList),
          //     blockipAPI.blockipBatchDelete(deleteIpList),
          //   ]);
          //   api.success({
          //     message: "Save successful!",
          //   });
          // } else if (createIpList.length > 0 && !deleteIpList.length) {
          //   await blockipAPI.blockipBatchCreate(createIpList);
          //   api.success({
          //     message: "Save successful!",
          //   });
          // } else if (deleteIpList.length > 0 && !createIpList.length) {
          //   await blockipAPI.blockipBatchDelete(createIpList);
          //   api.success({
          //     message: "Save successful!",
          //   });
          // }
          getIpWihteListData();
        } catch (error) {
          console.error(error);
        }
      }

      if (isReq !== isStatus) {
        console.log("hahaha");
        await blockipAPI.blockipSwitch({ status });
        await handleGetIpblockStatus();
        api.success({
          message: "Save successful!",
        });
      }
    },
    [getIpWihteListData, isReq, api, oldIpList, handleGetIpblockStatus]
  );
  const reset = useCallback(() => {
    form.setFieldValue("ips", oldIpList);
  }, [form, oldIpList]);

  return (
    <section style={{ height: "100%" }}>
      {contextHolder}
      <h2 className="las-pages-title">IP Whitelist</h2>
      <div style={{ backgroundColor: "#fff", padding: 20 }}>
        <Form onFinish={handleSubmit} form={form}>
          <Space direction="vertical" size={20} style={{ width: "100%" }}>
            <Space size={50}>
              <span>Enable IP Whitelist</span>
              <FormItem noStyle name={"status"}>
                <CheckButtonP
                  true={"enable"}
                  false={"disable"}
                  onChange={(status) =>
                    setShowIpList(status === "enable" ? true : false)
                  }
                />
              </FormItem>
            </Space>
            {showIpList && (
              <>
                <Alert
                  message="Before saving, please ensure that the current device's network IP has been recorded; otherwise, the system will not be usable."
                  type="warning"
                  showIcon
                  closable
                />
                <FormItem
                  rules={[
                    {
                      validator: (_: any, value: string | any[]) => {
                        if (!value || value.length === 0) {
                          return Promise.reject(
                            new Error("IP Whitelist cannot be empty!")
                          );
                        }
                        const ipRegex =
                          /^(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])$/;
                        for (let ip of value) {
                          if (!ipRegex.test(ip)) {
                            return Promise.reject(
                              new Error(`Invalid IP address: ${ip}`)
                            );
                          }
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                  name={"ips"}
                >
                  <Select
                    mode="tags"
                    style={{ width: "100%" }}
                    placeholder="IP Whitelist"
                    open={false}
                    // options={ipList}
                  />
                </FormItem>
              </>
            )}
            {(showIpList === true || isReq !== showIpList) && (
              <FormItem>
                <Space>
                  <Button style={{ width: 100 }} onClick={reset}>
                    Cancel
                  </Button>
                  <Button
                    style={{ width: 100 }}
                    type="primary"
                    htmlType="submit"
                  >
                    Save
                  </Button>
                </Space>
              </FormItem>
            )}
          </Space>
        </Form>
      </div>
    </section>
  );
};

export default IpWhitelist;
