import { ISearchPage } from "@/common/template/interface";
import { BaseAPI } from "../base/baseApi";

export class BatchAPI extends BaseAPI {
  // sftp配置修改
  async setSftpConfig(data: ISetSftpConfig) {
    return this.post("/batch/sftp/config ", data);
  }
  // sftp配置查询
  async getSftpConfig() {
    return this.get("/batch/sftp/config");
  }
  // sftp推送文件
  async sendSftp() {
    return this.post("/batch/sftp/send");
  }
  // sftp拉取文件
  async fetchSftp(data: IFetchSftp) {
    return this.get("/batch/sftp/fetch", data);
  }
  // 测试sftp
  async testSftp() {
    return this.get("/batch/sftp/test");
  }
  // 设置sftp拉取时间
  async jobSftp(data: IJobSftp[]) {
    return this.post("/batch/sftp/job", data);
  }

  async logsBatchJob(data: ISearchPage) {
    return this.get("/batch/job/log", data);
  }
}

const batchAPI = new BatchAPI();
export default batchAPI;

export interface ISetSftpConfig {
  host: string;
  port: string;
  username: string;
  password: string;
  path: string;
}

export interface IFetchSftp {
  fileName: string;
}

export interface IJobSftp {
  type: number;
  trigger: Date;
}
