import { ICustomizeCompProps, ITrigger } from "@/common/interface";
import { FC } from "react";
import { Switch } from "antd";

const CheckButtonP: FC<
  ICustomizeCompProps & {
    true: number | string;
    false: number | string;
    disable?: boolean;
    onClick?: ITrigger;
  }
> = (props) => {
  const click = () => {
    if (props.disable) {
      return;
    }
    if (props.onClick) {
      props.onClick();
    }
    if (props.onChange) {
      props.onChange(!(props.value === props.true) ? props.true : props.false);
    }
  };

  return (
    <Switch
      disabled={props.disable}
      onChange={click}
      checked={props.value === props.true ? true : false}
    />
  );
};

export default CheckButtonP;
