import Template from "@/common/template";
import { IColumnsTypeP, IOperationConfig } from "@/common/template/interface";
import useEventId from "@/hooks/useEventId";
import { useNavigate } from "react-router-dom";
import { FC, useCallback } from "react";
import "./index.less";
import subscriptionAPI from "@/api/apis/subscription";

import EllipsisTooltip from "@/common/template/components/ellipsisTooltip";
import dayjs from "dayjs";
interface UserIDataItem {
  amount: number;
  created_at: string;
  created_at_human: string;
  currency: string;
  default: string;
  id: number;
  package: string;
  updated_at: string;
  updated_at_human: string;
  valid_from: string;
  valid_to: string;
}

const Subscription: FC = () => {
  const templateId = useEventId();
  const navigate = useNavigate();
  const queryData = useCallback((data: any) => {
    return subscriptionAPI.getSubscriptionList(data);
  }, []);
  const optList: IOperationConfig = [
    {
      text: "Modify",
      icon: "icon-a-Group3612",
      event: (data) => {
        navigate(`/admin/subscription_rates/${data.id}/edit`, {
          state: data,
        });
      },
    },
  ];
  const columns: IColumnsTypeP<UserIDataItem>[] = [
    // {
    //   key: "id",
    //   title: "Package id",
    //   dataIndex: "id",
    // },
    {
      key: "package",
      title: "Package",
      dataIndex: "package",
      render(text, data) {
        return (
          <span
            onClick={() => {
              navigate(`/admin/subscription_rates/${data.id}/edit`, {
                state: data,
              });
            }}
            style={{ color: "#01A6F3", cursor: "pointer" }}
          >
            {text}
          </span>
        );
      },
    },
    {
      key: "amount",
      title: "Amount",
      dataIndex: "amount",
    },
    {
      key: "valid_from",
      title: "Validity Period",
      dataIndex: "valid_from",

      render(e, data) {
        const times = `${dayjs(e).format("MMM DD, YYYY")} - ${dayjs(
          data?.valid_to
        ).format("MMM DD, YYYY")}`;
        return <EllipsisTooltip title={times}>{times}</EllipsisTooltip>;
      },
    },
    {
      key: "default",
      title: "Default",
      dataIndex: "default",
      render(text) {
        return text === "Y" ? "Yes" : " No";
      },
    },
  ];
  return (
    <section style={{ height: "100%" }}>
      <Template
        id={templateId}
        title={"Subscription"}
        subTitle="management"
        optList={optList}
        queryData={queryData}
        search
        searchKeyWord="keyword"
        rowKey={"id"}
        config={columns}
        create={() => {
          navigate("/admin/subscription_rates/new");
        }}
      />
    </section>
  );
};

export default Subscription;
