import batchAPI from "@/api/apis/batch";
import Template from "@/common/template";
import { filterEmptyProperties } from "@/common/utils/untils";
import { transformOptionsValueWeb } from "@/common/utils/untilsX";
import SelectorDataFromTo from "@/components/SelectorDataFromTo";
import useEventId from "@/hooks/useEventId";
import { Tag } from "antd";
import { handleTimeFormat } from "@/common/utils/untilsX";
import { FC, SetStateAction, useCallback, useState } from "react";
const TypsEm = [
  { id: 1, name: "manual" },
  { id: 2, name: "auto" },
];
const AuditLogsBatchEngine: FC = () => {
  const [startTime, setStartTime] = useState<string>("");
  const [endTime, setEndTime] = useState<string>("");
  const templateId = useEventId();
  const queryData = (data: any) => {
    return batchAPI.logsBatchJob(data);
  };
  const change = useCallback(
    (e: {
      startTime: SetStateAction<string>;
      endTime: SetStateAction<string>;
    }) => {
      setStartTime(e.startTime ? e.startTime : "");
      setEndTime(e.endTime ? e.endTime : "");
    },
    []
  );

  const columns = [
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
    },
    {
      key: "type",
      title: "Type",
      dataIndex: "type",
      render: (type: 1 | 2) => {
        return transformOptionsValueWeb(TypsEm, type);
      },
    },
    {
      key: "remark",
      title: "Remark",
      dataIndex: "remark",
    },

    {
      key: "start_date_time",
      title: "Start time",
      dataIndex: "start_date_time",
      render: (e: string) => handleTimeFormat(e, "MMM DD, YYYY hh:mm A"),
    },
    {
      key: "end_date_time",
      title: "End time",
      dataIndex: "end_date_time",
      render: (e: string) => handleTimeFormat(e, "MMM DD, YYYY hh:mm A"),
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      render: (type: 1 | 2) => {
        switch (type) {
          case 1:
            return (
              <Tag color="#87d068" style={{ width: 60, textAlign: "center" }}>
                Succeed
              </Tag>
            );
          case 2:
            return (
              <Tag color="#f50" style={{ width: 60, textAlign: "center" }}>
                Failed
              </Tag>
            );
          default:
            return "-";
        }
      },
    },
  ];
  return (
    <section>
      <div className="audit-logs-box">
        <h2 className="las-pages-title">
          Audit Logs <span>Batch Engine</span>
        </h2>
        <SelectorDataFromTo onChange={change} />
        <div className="bg-white" style={{ marginTop: 15 }}>
          <Template
            id={templateId}
            queryData={queryData}
            rowKey={"id"}
            // showFooter={false}
            config={columns}
            moreParams={filterEmptyProperties({
              start_date_time: startTime,
              end_date_time: endTime,
            })}
          />
        </div>
      </div>
    </section>
  );
};

export default AuditLogsBatchEngine;
