import { ISearchPage } from "@/common/template/interface";
import { BaseAPI } from "../base/baseApi";

export class SubscriptionAPI extends BaseAPI {
  async getSubscriberReportFinance(data: IGetSubscriberReportFinance) {
    return this.get("/subscriber/report/finance", data);
  }
  /** 服务包列表 */
  async getSubscriptionList(data: ISearchPage) {
    return this.get("/subscription/list", data);
  }

  /** 添加服务包 */
  async addSubscription(data: IAddSubscription) {
    return this.post("/subscription/add", data);
  }

  /** 更新服务包 */
  async updateSubscription(data: IUpdateSubscription) {
    return this.post("/subscription/update", data);
  }

  /** 服务包下拉列表 */
  async getSubscriptionSelectList() {
    return this.get("/subscription/select");
  }
}

const subscriptionAPI = new SubscriptionAPI();
export default subscriptionAPI;

export interface IGetSubscriberReportFinance {
  page: number;
  pageSize: number;
  account_number?: string;
  request_date?: string;
  activation_date?: string;
  termination_date?: string;
  billing_cycle?: string;
  start_date?: string;
  end_date?: string;
  transaction_id?: string;
  amount?: number;
}

export interface IAddSubscription {
  package: string;
  amount: number;
  start_date_time: string;
  end_date_time: string;
  default: boolean;
}

export interface IUpdateSubscription {
  id: number;
  package: string;
  amount: number;
  start_date_time: string;
  end_date_time: string;
  default: boolean;
}
