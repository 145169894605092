import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { FC, useCallback } from "react";
import "../auditLogsUsers/index.less";
const CustomerService: FC = () => {
  const navigate = useNavigate();
  const goAuditLogsUsersList = useCallback(() => {
    navigate("/admin/reports/customer_service/valid");
  }, [navigate]);

  const goAuditLogsUsersHistory = useCallback(() => {
    navigate("/admin/reports/customer_service/terminated");
  }, [navigate]);
  return (
    <section>
      <div className="audit-logs-users audit-logs-box">
        <h2 className="las-pages-title">
          Customer Service <span>menu</span>
        </h2>
        <Row gutter={[20, 0]}>
          <Col span={7}>
            <div
              onClick={goAuditLogsUsersList}
              className="audit-logs-users-box audit-logs-users-box-1"
            >
              <p className="audit-logs-users-text-1">Valid</p>
              <p className="audit-logs-users-text-2">
                List of active subscribers.
              </p>
            </div>
          </Col>
          <Col span={7}>
            <div
              onClick={goAuditLogsUsersHistory}
              className="audit-logs-users-box audit-logs-users-box-2"
            >
              <p className="audit-logs-users-text-1">Terminated</p>
              <p className="audit-logs-users-text-2">
                List of terminated subscribers.
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default CustomerService;
