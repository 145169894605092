import useStore from "@/store/store";
import { Col, Dropdown, Layout, MenuProps, Row, Space, theme } from "antd";
import { FC, useCallback, useMemo, useRef } from "react";
// import { UserOutlined } from "@ant-design/icons";
import useMessageServiceStore from "@/store/messageServiceStore";
import { useNavigate } from "react-router-dom";
import "./index.less";
import useUserInfo from "@/hooks/useUserInfo";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { ICallback } from "@/common/interface";
interface HeaderProps {
  collapsed: boolean;
  setCollapsed: ICallback<boolean>;
}
const HeaderPlx: FC<HeaderProps> = ({ collapsed, setCollapsed }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const { current: _setCollapsed } = useRef(setCollapsed);
  const { logout } = useStore();
  const { userInfo } = useUserInfo();
  const { confirm } = useMessageServiceStore();
  const navigate = useNavigate();
  const handelLouOut = useCallback(() => {
    confirm(`Are you sure you want to log out?`, () => {
      logout();
      navigate("/login");
    });
  }, [confirm, logout, navigate]);
  // const goPage = useCallback(
  //   (key: string) => {
  //     navigate(key);
  //   },
  //   [navigate]
  // );
  const items: MenuProps["items"] = useMemo(() => {
    const list = [
      // {
      //   key: "account",
      //   label: (
      //     <p style={{ padding: "3px 10px" }} onClick={() => {}}>
      //       Account
      //     </p>
      //   ),
      // },

      {
        key: "log_out",
        label: (
          <p style={{ padding: "3px 10px" }} onClick={handelLouOut}>
            Logout
          </p>
        ),
      },
    ];
    // if (
    //   userInfo?.access_module_codes.find(
    //     (item) => item.code === "AUTHENTICATION_MANAGEMENT"
    //   )
    // ) {
    //   list.unshift({
    //     key: "authentication",
    //     label: (
    //       <p
    //         style={{ padding: "3px 10px" }}
    //         onClick={() => goPage("/admin/register2fa")}
    //       >
    //         Authentication
    //       </p>
    //     ),
    //   });
    // }
    return list;
  }, [handelLouOut]);
  return (
    <Layout.Header
      className="header-container"
      style={{ padding: 0, background: colorBgContainer }}
    >
      <div style={{ paddingRight: 34 }}>
        <Row justify={"space-between"}>
          <Col>
            {collapsed ? (
              <MenuUnfoldOutlined
                onClick={() => {
                  _setCollapsed(!collapsed);
                }}
                style={{ fontSize: 20, cursor: "pointer", marginLeft: 8 }}
              />
            ) : (
              <MenuFoldOutlined
                onClick={() => {
                  _setCollapsed(!collapsed);
                }}
                style={{ fontSize: 20, cursor: "pointer", marginLeft: 8 }}
              />
            )}
          </Col>
          <Col>
            <Dropdown trigger={["click"]} menu={{ items }}>
              <Space style={{ cursor: "pointer" }}>
                <span className="header-user-name">{userInfo?.name}</span>
              </Space>
            </Dropdown>
          </Col>
        </Row>
      </div>
    </Layout.Header>
  );
};

export default HeaderPlx;
