import {
  Button,
  Col,
  Empty,
  Form,
  Input,
  Layout,
  Row,
  Spin,
  notification,
} from "antd";
import { FC, useCallback, useEffect, useState } from "react";
import "./index.less";
import { IMapT } from "@/common/interface";
import useUILoading from "@/hooks/useLoading";
import meetingAPI from "@/api/apis/meetings";
import FormS from "@/common/form/FormS";
import useEventId from "@/hooks/useEventId";
import useMessageServiceStore from "@/store/messageServiceStore";
import { MinusCircleFilled } from "@ant-design/icons";
import BreadcrumbPlx from "@/common/breadcrumbPlx";
const ManageMeetings: FC = () => {
  const [loading, open, close] = useUILoading();
  const formId = useEventId();
  const { confirm, formUpdate } = useMessageServiceStore();
  const [form2] = Form.useForm();
  const [notificationApi, contextHolder] = notification.useNotification();
  const [meetingList, setMeetingList] = useState<any[]>([]);
  const getMeetingList = useCallback(async () => {
    open();
    const res = await meetingAPI.mettingList();
    close();
    if (res) {
      setMeetingList(res);
      const _data: IMapT<string> = {};
      res.forEach((item: { name: string }) => {
        _data[item.name] = item.name;
      });
      formUpdate(formId, _data);
    }
  }, [open, close, formId, formUpdate]);
  const submitMeeting = useCallback(
    ({ name }: any) => {
      if (name) {
        open();
        meetingAPI
          .addMeeting({ name })
          .then(() => {
            getMeetingList();
            form2.setFieldValue("name", "");
            notificationApi.success({ message: "Created successfully" });
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(close);
      }
    },
    [form2, notificationApi, close, getMeetingList, open]
  );
  const saveMeeting = useCallback(
    (data: any) => {
      try {
        open();
        const _data = { ...data };
        const lastData = Object.entries(_data).map(([id, name]: any) => {
          const obj = meetingList.find(
            (item: { id: number }) => item.id === Number(id)
          );
          return {
            ...obj,
            id: Number(id),
            name,
          };
        });
        meetingAPI.updateMeeting(lastData).then((res) => {
          if (res !== null) {
            getMeetingList();
            notificationApi.success({ message: "Saved successfully" });
          }
        });
        setTimeout(() => {
          close();
        }, 300);
      } catch (error) {}
    },
    [open, close, getMeetingList, notificationApi, meetingList]
  );
  const deleteMeeting = useCallback(
    (id: number) => {
      try {
        open();
        confirm("Are you sure to delete the meeting?", async () => {
          await meetingAPI.meetingDelete({ id });
          getMeetingList();
          notificationApi.success({ message: "Delete successfully" });
        });
      } catch (error) {
        console.log(error);
      } finally {
        close();
      }
    },
    [open, close, getMeetingList, confirm, notificationApi]
  );
  useEffect(() => {
    getMeetingList();
  }, [getMeetingList]);

  return (
    <Spin spinning={loading}>
      {contextHolder}
      <Layout className="manage-meeting-container">
        <Row gutter={15}>
          <Col span={12}>
            <div className="meeting-left-container">
              <BreadcrumbPlx items={["Meeting", "Edit"]} />
              <FormS
                id={formId}
                footerBtnConfig={{
                  okText: "Save",
                }}
                submitEvent={saveMeeting}
              >
                {meetingList.length ? (
                  <>
                    {meetingList.map((item: any) => (
                      <Row key={item?.id} align={"middle"} gutter={40}>
                        <Col flex={1}>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "The meeting name cannot be empty!",
                              },
                            ]}
                            initialValue={item.name}
                            name={item.id}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col>
                          <Form.Item>
                            <span
                              style={{
                                fontSize: 20,
                                color: "#999",
                                cursor: "pointer",
                              }}
                              onClick={() => deleteMeeting(item.id)}
                            >
                              <MinusCircleFilled />
                            </span>
                          </Form.Item>
                        </Col>
                      </Row>
                    ))}
                  </>
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </FormS>
            </div>
          </Col>
          <Col span={12}>
            <div className="meeting-right-container">
              <h3>Create New Meeting</h3>
              <Form form={form2} layout="vertical" onFinish={submitMeeting}>
                <div className="input-container">
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "The meeting name cannot be empty!",
                        whitespace: true,
                      },
                      {
                        validator(_, value) {
                          const trimmedValue = value.trim();
                          if (trimmedValue === "") {
                            return Promise.resolve();
                          }
                          if (
                            meetingList.find((e) => e.name === trimmedValue)
                          ) {
                            return Promise.reject(
                              new Error("Meeting already exists!")
                            );
                          }

                          return Promise.resolve();
                        },
                      },
                    ]}
                    name={"name"}
                    label={"Name"}
                  >
                    <Input maxLength={50} showCount />
                  </Form.Item>
                </div>

                <Button
                  htmlType="submit"
                  style={{ marginTop: 30 }}
                  type="primary"
                >
                  Submit
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
      </Layout>
    </Spin>
  );
};

export default ManageMeetings;
