import { ICallback } from "@/common/interface";
import useStore from "@/store/messageServiceStore";
import { useEffect } from "react";
import EInfoType from "@/store/infoType";

const useResourceData = (
  type: EInfoType,
  id: number,
  trigger: ICallback<any>
) => {
  useEffect(() => {
    const unsubscribe = useStore.subscribe(({ messageInfo }: any) => {
      const latestMessage = messageInfo;
      if (
        latestMessage &&
        latestMessage.type === type &&
        latestMessage.value.id === id
      ) {
        if (latestMessage.value.data) {
          trigger(latestMessage.value.data);
        } else {
          trigger(null);
        }
      }
    });

    return unsubscribe;
  }, [type, id, trigger]);
};

export default useResourceData;
