import { ICallback } from "@/common/interface";
import { Button, Col, Form, Input, Row } from "antd";
import { FC, useCallback, useRef } from "react";
import "./index.less";
import ValidDataTime from "./ValidDataTime";
interface ISelectorDataFromTo {
  onChange: ICallback<any>;
  showAccountNumber?: boolean;
}
const SelectorDataFromTo: FC<ISelectorDataFromTo> = ({
  onChange,
  showAccountNumber,
}) => {
  const { current: change } = useRef(onChange);
  const onFinish = useCallback(
    (values: any) => {
      if (change) {
        if (showAccountNumber) {
          change({
            ...values?.dateTime,
            account_number: values?.account_number,
          });
        } else {
          change(values?.dateTime);
        }
      }
    },
    [change, showAccountNumber]
  );
  return (
    <section>
      <div className="selector-data-from-to-box">
        <Form layout="vertical" onFinish={onFinish}>
          {showAccountNumber && (
            <Row style={{ maxWidth: 683 }} gutter={16}>
              <Col flex={1}>
                <Form.Item label={"Account Number"} name={"account_number"}>
                  <Input style={{ width: "100%" }}></Input>
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row>
            <Form.Item noStyle name={"dateTime"}>
              <ValidDataTime />
            </Form.Item>
            <Col span={10}></Col>
            <Col span={24}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Search
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </section>
  );
};

export default SelectorDataFromTo;
