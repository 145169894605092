import batchAPI, {
  IFetchSftp,
  IJobSftp,
  ISetSftpConfig,
} from "@/api/apis/batch";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  TimePicker,
  notification,
} from "antd";
import { FC, useCallback, useEffect, useMemo } from "react";
import "./index.less";
import useSubModules from "@/hooks/useSubModules";
import configAPI from "@/api/apis/config";
import dayjs from "dayjs";
type FieldType = {
  host: string;
  port: string;
  username: string;
  password: string;
  path: string;
};
type FieldType2 = {
  sendTime: string;
  processTime: string;
};
const format = "HH:mm";
const ManageBatchJob: FC = () => {
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [notificationApi, contextHolder] = notification.useNotification();
  const subModulesList = useSubModules();
  const changManualExecution = useMemo(() => {
    if (Array.isArray(subModulesList) && subModulesList?.length > 0) {
      return !subModulesList.includes("manual_execution") ? true : false;
    } else {
      return true;
    }
  }, [subModulesList]);
  const label = (str: string) => (
    <span style={{ color: "#A5A5A5", fontSize: 18 }}>{str}</span>
  );
  const setTime = useCallback(
    (values: any) => {
      const value = {
        sendTime: values["sendTime"]?.format(format),
        processTime: values["processTime"]?.format(format),
      };
      let data: IJobSftp[] = [];
      if (value.sendTime) {
        const send = {
          type: 1,
          trigger: value.sendTime,
        };
        data.push(send);
      }
      if (value.processTime) {
        const fetch = {
          type: 2,
          trigger: value.processTime,
        };
        data.push(fetch);
      }

      batchAPI
        .jobSftp(data)
        .then(() => {
          form2.setFieldsValue(values);
          notificationApi.success({
            message: "BMCS SFTP Configuration Successful",
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    [notificationApi, form2]
  );
  const generateBatchFile = useCallback(
    async (file: any) => {
      try {
        await batchAPI.sendSftp();
        notificationApi.success({ message: "Generate successfully!" });
      } catch (error) {
        console.error(error);
      }
    },
    [notificationApi]
  );

  const processFilefromBMCS = useCallback(() => {
    const data: IFetchSftp = {
      fileName: "test.png",
    };
    batchAPI
      .fetchSftp(data)
      .then(() => {
        notificationApi.success({ message: "Pulled successfully!" });
      })
      .catch((e) => {
        console.log(e);
      });
  }, [notificationApi]);

  const onFinish = useCallback(
    async (values: any) => {
      const data: FieldType = values;
      const _data: any = { ...data };
      _data.port = Number(_data.port);
      batchAPI
        .setSftpConfig(_data)
        .then((res) => {
          notificationApi.success({ message: "Configuration successful" });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    [notificationApi]
  );
  // const generateBatchProps: UploadProps = {
  //   name: "file",
  //   showUploadList: false,
  //   multiple: false,
  //   beforeUpload(file) {
  //     return false;
  //   },
  //   async onChange(info: UploadChangeParam<UploadFile<any>>) {
  //     console.log(info);
  //     batchAPI
  //       .sendSftp(info.file)
  //       .then(() => {
  //         notificationApi.success({ message: "Uploaded successfully" });
  //       })
  //       .catch((e) => {
  //         console.log(e);
  //       });
  //   },
  //   onDrop(e) {
  //     console.log("Dropped files", e.dataTransfer.files);
  //   },
  // };
  const testSftp = useCallback(() => {
    console.log("testSftp");
    batchAPI
      .testSftp()
      .then((res) => {
        if (!res) {
          notificationApi.error({
            message: "Sftp Configuration Test Failed!",
          });
        } else {
          notificationApi.success({
            message: "Sftp Configuration Test Successful!",
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [notificationApi]);

  useEffect(() => {
    try {
      batchAPI
        .getSftpConfig()
        .then((res: ISetSftpConfig) => {
          form.setFieldsValue(res);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      console.log(error);
    }
    return () => {};
  }, [form]);
  const handleGetJobConfig = useCallback(async () => {
    try {
      configAPI.getJobSendConfig();
      configAPI.getJobFetchConfig();
      const res = await Promise.all([
        configAPI.getJobSendConfig(),
        configAPI.getJobFetchConfig(),
      ]);
      if (res[0] && res[1]) {
        form2.setFieldsValue({
          sendTime: dayjs(res[0].Value, format),
          processTime: dayjs(res[1].Value, format),
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [form2]);
  useEffect(() => {
    handleGetJobConfig();
  }, [handleGetJobConfig]);

  return (
    <section>
      <div className="audit-logs-box">
        {contextHolder}
        <h2 className="las-pages-title">
          Batch Engine <span>management</span>
        </h2>
        <Row gutter={[30, 0]}>
          <Col span={12}>
            <div className="bmcs-sftp-configuration">
              <h2>BMCS SFTP Configuration</h2>
              <Row>
                <Col xl={{ span: 24 }} xxl={{ span: 18 }}>
                  <Form
                    form={form}
                    onFinish={onFinish}
                    autoComplete="off"
                    layout="vertical"
                  >
                    <Form.Item<FieldType>
                      label={label("Host Server")}
                      name="host"
                      rules={[
                        {
                          required: true,
                          message: "Please input your host server!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item<FieldType>
                      label={label("Port")}
                      name="port"
                      rules={[
                        { required: true, message: "Please input your port!" },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item<FieldType>
                      label={label("Username")}
                      name="username"
                      rules={[
                        {
                          required: true,
                          message: "Please input your username!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item<FieldType>
                      label={label("Password")}
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item<FieldType>
                      label={label("Path")}
                      name="path"
                      rules={[
                        { required: true, message: "Please input your path!" },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item>
                      <div style={{ marginTop: 40 }}>
                        <Space size={"large"}>
                          <Button
                            size="large"
                            style={{ width: 100 }}
                            type="primary"
                            htmlType="submit"
                          >
                            Save
                          </Button>
                          <Button
                            onClick={testSftp}
                            size="large"
                            style={{ width: 248, backgroundColor: "#109626" }}
                            type="primary"
                          >
                            Test SFTP Configuration
                          </Button>
                        </Space>
                      </div>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={12}>
            <div className="batch-timing">
              <div className="batch-timing-top">
                <h2>Batch timing</h2>
                <Row>
                  <Col xl={{ span: 24 }} xxl={{ span: 18 }}>
                    <Form
                      form={form2}
                      onFinish={setTime}
                      autoComplete="off"
                      layout="vertical"
                    >
                      <Form.Item<FieldType2>
                        label={label("Generate and Send Text File to BMCS")}
                        name="sendTime"
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value && !getFieldValue("processTime")) {
                                return Promise.reject(
                                  new Error("Please select at least one time!")
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <TimePicker format={format} style={{ width: "100%" }} />
                      </Form.Item>
                      <Form.Item<FieldType2>
                        label={label("Fetch and Process Text File to BMCS")}
                        name="processTime"
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value && !getFieldValue("sendTime")) {
                                return Promise.reject(
                                  new Error("Please select at least one time!")
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <TimePicker format={format} style={{ width: "100%" }} />
                      </Form.Item>
                      <Form.Item
                        wrapperCol={{
                          style: {
                            marginTop: 40,
                          },
                        }}
                      >
                        <Button
                          style={{ width: 144, height: 38, fontSize: 16 }}
                          type="primary"
                          htmlType="submit"
                        >
                          Save
                        </Button>
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
              </div>
              <div className="batch-timing-bottom">
                <h2>Manual Execution</h2>
                <Row gutter={[0, 20]}>
                  <Col span={24}>
                    {/* <Button onClick={generateBatchFile} type="primary" danger style={{ width: 215, height: 38, backgroundColor: "#FA0100" }}>Generate Batch File</Button> */}
                    {/* <Upload
                      {...generateBatchProps}
                      customRequest={generateBatchFile}
                    > */}
                    <Button
                      onClick={generateBatchFile}
                      type="primary"
                      disabled={changManualExecution}
                      danger
                      style={{
                        width: 215,
                        height: 38,
                        backgroundColor: "#FA0000",
                      }}
                    >
                      Generate Batch File
                    </Button>
                    {/* </Upload> */}
                  </Col>
                  <Col span={24}>
                    <Button
                      disabled={changManualExecution}
                      onClick={processFilefromBMCS}
                      type="primary"
                      danger
                      style={{
                        width: 250,
                        height: 38,
                        backgroundColor: "#FAC400",
                      }}
                    >
                      Process File from BMCS
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        <p className="batch-footer-text">
          This is the default dialog which is useful for displaying information,
          The dialog window can be moved, resized and closed with the 'x' icon.
        </p>
      </div>
    </section>
  );
};

export default ManageBatchJob;
