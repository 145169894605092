import { Form, Input, Layout, notification } from "antd";
import { FC, useEffect, useState, useCallback } from "react";
import "./index.less";
import FormS from "@/common/form/FormS";
import useMessageServiceStore from "@/store/messageServiceStore";
import { useLocation, useNavigate } from "react-router-dom";
import useEventId from "@/hooks/useEventId";
import SelectP from "@/common/form/SelectP";
import subscriptionAPI from "@/api/apis/subscription";
import subscriberAPI from "@/api/apis/subscriber";
import BreadcrumbPlx from "@/common/breadcrumbPlx";
const NewSubscriber: FC = () => {
  const formId = useEventId();
  const { formUpdate } = useMessageServiceStore();
  const navigate = useNavigate();
  const [hidePwd, setHidePwd] = useState<boolean>(false);
  const location = useLocation();
  const [subPackageList, setsubPackageList] = useState([]);
  useEffect(() => {
    const data: any = location.state;
    if (data) {
      const _data = {
        id: data.id,
        subscription_rate_id: data?.subscription_rate_id,
        account_number: data?.account_number,
        mode: "edit",
      };
      formUpdate(formId, _data);
      setHidePwd(true);
    }
  }, [location, formId, formUpdate]);

  const submit = useCallback(
    async (data: any) => {
      const value = { ...data, account_number: data.account_number?.trim() };
      try {
        if (value?.mode === "edit") {
          delete value.mode;
          await subscriberAPI.subscriberUpdate(value);
          notification.success({
            message: "Update success",
          });
        } else {
          // 新增
          await subscriberAPI.subscriberAdd(value);
          notification.success({
            message: "Add success",
          });
        }
        navigate("/admin/subscribers");
      } catch (error) {
        console.error(error);
      }
      // navigate("/subscriber");
    },
    [navigate]
  );
  const getPackageList = useCallback(() => {
    subscriptionAPI
      .getSubscriptionSelectList()
      .then((res) => {
        if (res?.length) {
          // console.log(res);

          // const _list: any = [];
          // for (const key in res) {
          //   if (Reflect.hasOwnProperty.call(res, key)) {
          //     _list.push({
          //       id: res[key]["key"],
          //       name: res[key]["value"],
          //     });
          //   }
          // }
          // console.log(_list);

          setsubPackageList(res);
        }
      })
      .catch((err) => {
        throw err;
      });
  }, []);
  useEffect(() => {
    getPackageList();
  }, [getPackageList]);

  return (
    <Layout>
      <BreadcrumbPlx
        items={["Subscriber", location.state ? "update" : "new"]}
      />
      <FormS
        id={formId}
        footerBtnConfig={{ okText: hidePwd ? "Edit" : "Add" }}
        submitEvent={submit}
        layout="vertical"
      >
        <main className="page-container subscriber-new-main">
          <div style={{ width: 500 }}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Account Number cannot be empty!",
                },
              ]}
              name="account_number"
              label="Account Number"
            >
              <Input />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Subscription package cannot be empty!",
                },
              ]}
              name="subscription_rate_id"
              label="Subscription package"
            >
              <SelectP
                config={{
                  idKey: "key",
                  textKey: "value",
                }}
                data={subPackageList}
              />
            </Form.Item>
          </div>
        </main>
      </FormS>
    </Layout>
  );
};

export default NewSubscriber;
