// import { parsePermissionTreeNodesTiled } from "@/common/utils/untilsX";
import useUserInfo from "@/hooks/useUserInfo";
import useStore from "@/store/store";
import React, { FC } from "react";
import { Navigate, useLocation } from "react-router-dom";

const RouterAuth: FC<any> = ({ children }) => {
  const { userInfo } = useUserInfo();
  const location = useLocation();

  if (userInfo) {
    if (userInfo?.two_factor_authentication === 0) {
      if (location.pathname === "/admin/register2fa") {
        return children;
      }
      return <Navigate to="/admin/register2fa" />;
    }

    return children;
  } else {
    return <Navigate to="/login" />;
  }
};
export default RouterAuth;

export const RedirectIfAuthenticated: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const { userInfo } = useUserInfo();
  if (userInfo) {
    return <Navigate to="/admin" />;
  } else {
    return children;
  }
};

export const RedirectIfAuthenticated2Fa: React.FC<{
  children: JSX.Element;
}> = ({ children }) => {
  const { userInfo } = useUserInfo();
  const { twoFaFlag } = useStore();
  if (!userInfo) {
    return <Navigate to="/login" />;
  } else {
    if (twoFaFlag) {
      return children;
    } else {
      return <Navigate to="/admin" />;
    }
  }
};
