import { ISearchPage } from "@/common/template/interface";
import { BaseAPI } from "../base/baseApi";

export class BlockipAPI extends BaseAPI {
  async blockipCreate(data: IBlockipCreateParams) {
    return this.post("/blockip/create", data);
  }
  async blockipUpdate(data: IBlockipUpdateParams) {
    return this.post("/blockip/update", data);
  }
  async blockipList(params: ISearchPage) {
    return this.get("/blockip/list", {}, { params });
  }
  async blockipDelete(params: IBlockipDeleteParams) {
    return this.post("/blockip/delete", {}, { params });
  }
  // 是否开启了ip白名单
  async blockipStatus() {
    return this.get("/blockip/status");
  }
  // 开启关闭ip白名单
  async blockipSwitch(params: IBlockipSwitchParams) {
    return this.post("/blockip/switch", {}, { params });
  }
  // 批量新增ip白名单
  async blockipBatchCreate(data: string[]) {
    return this.post("/blockip/batchadd", data);
  }
  // 批量删除ip白名单DDD
  async blockipBatchDelete(data: string[]) {
    return this.post("/blockip/batchdel", data);
  }
}

const blockipAPI = new BlockipAPI();
export default blockipAPI;

interface IBlockipSwitchParams {
  status: "enable" | "disable";
}
interface IBlockipCreateParams {
  ip: string;
  remarks?: string;
}
interface IBlockipUpdateParams extends IBlockipCreateParams {
  id: number;
}
interface IBlockipDeleteParams {
  id: number;
}
