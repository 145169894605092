import { FC, useCallback, useEffect, useState } from "react";
import configAPI from "@/api/apis/config";
import { Col, Row, notification } from "antd";
import CheckButtonP from "@/components/checkButton";
import useMessageServiceStore from "@/store/messageServiceStore";

const ConfigurationList: FC = () => {
  const [ldapSwitch, setLdapSwitch] = useState<any>({});
  const [api, contextHolder] = notification.useNotification();
  const { confirm } = useMessageServiceStore();
  const getLdapData = useCallback(async () => {
    try {
      const res = await configAPI.configList({
        category: "ldap",
        key: "switch",
        page: 1,
        pageSize: 1,
      });
      if (res?.data?.length > 0) {
        setLdapSwitch(res.data[0]);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);
  useEffect(() => {
    getLdapData();
  }, [getLdapData]);
  const switckLdap = useCallback(
    async (e: "enable" | "disable") => {
      try {
        const _data = {
          ...ldapSwitch,
          value: e,
        };
        confirm(`Are you sure to ${e} ldap login?`, async () => {
          await configAPI.configUpdate(_data);
          getLdapData();
          api.success({
            message: "Update success",
          });
        });
      } catch (error) {
        console.error(error);
      }
    },
    [ldapSwitch, confirm, getLdapData, api]
  );

  return (
    <section style={{ height: "100%" }}>
      {contextHolder}
      <h2 className="las-pages-title">Ldap Management</h2>
      <div style={{ backgroundColor: "#fff", padding: 20 }}>
        <Row justify={"space-between"}>
          <Col>Ldap login switch</Col>
          <Col>
            <CheckButtonP
              true={"enable"}
              false={"disable"}
              onChange={switckLdap}
              value={ldapSwitch?.Value}
            />
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default ConfigurationList;
