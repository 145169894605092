import {
  Form,
  Input,
  Layout,
  Row,
  Space,
  TreeSelect,
  notification,
} from "antd";
import { FC, useEffect, useState, useCallback } from "react";
import "./index.less";
import FormS from "@/common/form/FormS";
import useMessageServiceStore from "@/store/messageServiceStore";
import { useLocation, useNavigate } from "react-router-dom";
import useEventId from "@/hooks/useEventId";
import { mapTreeNodeSelect, mapTreeNodeSelectIds } from "@/common/utils/untils";
import CustomeRadio from "@/components/customRadio";
import roleAPI from "@/api/apis/role";
import FormItem from "antd/es/form/FormItem";
import BreadcrumbPlx from "@/common/breadcrumbPlx";
const NewRores: FC = () => {
  const [ItreeData, setTreeData] = useState<any[]>([]);
  const [subOldModulestreeData, setOldSubModulestreeData] = useState<any[]>([]);
  const [newSubModulestreeData, setnewSubModulestreeData] = useState<any[]>([]);
  const [oldSubmitSubModulesData, setOldSubmitSubModulesData] = useState<any[]>(
    []
  );
  const [submitSubModulesData, setSubmitSubModulesData] = useState<any[]>([]);
  const [roleModuleList, setRoleModuleList] = useState<any[]>([]);

  const navigate = useNavigate();
  const getTreeList = useCallback(async () => {
    try {
      const res = await roleAPI.moduleSelect();
      setTreeData(mapTreeNodeSelect(res?.Modules));
      setOldSubModulestreeData(res?.SubModules);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getTreeList();
  }, [getTreeList]);

  const formId = useEventId();
  const { formUpdate } = useMessageServiceStore();
  const location = useLocation();
  const getCurrentRolePermission = useCallback(async (roleId: number) => {
    try {
      if (!roleId) {
        return [];
      }
      const res = await roleAPI.roleModuleList({ roleId });
      setRoleModuleList(res["node"]);
      return mapTreeNodeSelectIds(res["node"]);
    } catch (error) {
      console.error(error);
    }
  }, []);
  useEffect(() => {
    if (roleModuleList?.length) {
      const submo = roleModuleList
        .map((item: any) => {
          if (Array.isArray(item.child) && item.child.length) {
            return item.child.map((it: any) => {
              return {
                parent_code: item.code,
                code: it.code,
                name: it.name,
              };
            });
          }
          return null;
        })
        .filter((item: any) => item)
        .flat(1);
      setOldSubmitSubModulesData(submo);
      setSubmitSubModulesData(submo);
    }
  }, [roleModuleList]);
  const handleChangeSubModel = useCallback(
    (values: any) => {
      if (Array.isArray(values?.access_module_codes)) {
        setnewSubModulestreeData(
          subOldModulestreeData.filter((item) => {
            return values?.access_module_codes.find(
              (oldItem: { code: string }) => oldItem === item?.parent_code
            );
          })
        );
      }
    },
    [subOldModulestreeData]
  );
  const loadingData = useCallback(async () => {
    const data: any = location.state;
    if (data) {
      handleChangeSubModel({
        access_module_codes: await getCurrentRolePermission(data?.id),
      });
      const _data = {
        ...data,
        mode: "edit",
        access_module_codes: await getCurrentRolePermission(data?.id),
      };
      formUpdate(formId, _data);
    }
  }, [
    location,
    formId,
    formUpdate,
    getCurrentRolePermission,
    handleChangeSubModel,
  ]);

  useEffect(() => {
    loadingData();
  }, [loadingData]);
  const handelSubmitEvent = useCallback(
    async (data: any) => {
      const _data = { ...data, extend_module_codes: submitSubModulesData };
      try {
        delete _data.mode;
        await roleAPI.roleUpdate(_data);
        notification.success({
          message: "Update success",
        });
        navigate("/admin/roles");
      } catch (error) {
        console.error(error);
      }
    },
    [navigate, submitSubModulesData]
  );

  const handleSubmitSubModulesData = useCallback(
    (e: boolean, code: string) => {

      if (e) {
        setSubmitSubModulesData((predata: any) => {
          // if (predata.find((item: { code: string }) => item?.code === code))
          //   return predata;
          return [
            ...predata,
            ...newSubModulestreeData.filter((item) => item?.code === code),
          ];
        });
      } else {
        setSubmitSubModulesData((predata: any) =>
          predata.filter((item: any) => item?.code !== code)
        );
      }
    },
    [newSubModulestreeData]
  );
  const handleReset = useCallback(() => {
    setSubmitSubModulesData(oldSubmitSubModulesData);
    navigate("/admin/roles");
  }, [oldSubmitSubModulesData,navigate]);

  return (
    <Layout>
      <BreadcrumbPlx items={["Roles", "update"]} />
      <FormS
        id={formId}
        forceShowSubmit
        resetCallback={handleReset}
        onFieldsChange={handleChangeSubModel}
        footerBtnConfig={{ okText: "Edit" }}
        submitEvent={handelSubmitEvent}
        layout="vertical"
      >
        <main className="page-container user-new-main">
          <div style={{ width: 500 }}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Name cannot be empty!",
                },
              ]}
              name="name"
              label="Name"
            >
              <Input />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Description cannot be empty!",
                },
              ]}
              name="description"
              label="Description"
            >
              <Input.TextArea />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Permission cannot be empty!",
                },
              ]}
              name="access_module_codes"
            >
              <TreeSelect
                showSearch
                style={{ width: "100%" }}
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                allowClear
                multiple
                treeDefaultExpandAll
                treeData={ItreeData}
              />
            </Form.Item>
            {newSubModulestreeData.map((item) => (
              <Row key={item.code} style={{ marginBottom: 20 }}>
                <FormItem name={item.code} initialValue={true} noStyle>
                  <Space size={50}>
                    <CustomeRadio
                      defaultChecked={
                        submitSubModulesData.find((e) => e.code === item.code)
                          ? true
                          : false
                      }
                      true={true}
                      false={false}
                      onChange={(e) => handleSubmitSubModulesData(e, item.code)}
                    />
                    <span>{item.name}</span>
                  </Space>
                </FormItem>
              </Row>
            ))}
          </div>
        </main>
      </FormS>
    </Layout>
  );
};

export default NewRores;
