import { Col, Form, Row } from "antd";
import { FC } from "react";
import DateTimeItem from "./dateTimeItem";

const ValidDataTime: FC = () => {
  return (
    <Row gutter={20}>
      <Col flex={12}>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Valid Form cannot be empty!",
            },
          ]}
          name={"start_date_time"}
          label={"Valid Form"}
        >
          <DateTimeItem />
        </Form.Item>
      </Col>
      <Col flex={12}>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Termination Date cannot be empty!",
            },
          ]}
          name={"end_date_time"}
          label={"Termination Date"}
        >
          <DateTimeItem />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default ValidDataTime;
