//axios封装
// import { myRouter } from "@/router";
import useStore from "@/store/store";
import { getToken } from "@/store/token";
import { notification } from "antd";
import axios, { CreateAxiosDefaults } from "axios";
import resolveReq from "./resolve";
import useMessageServiceStore from "@/store/messageServiceStore";
export const base_url = "/api/v1";
let notificationDisplayed = false;

const reqService = (option: any, pass = false) => {
  return new Promise(async (resolve, reject) => {
    const config: CreateAxiosDefaults = {
      baseURL: base_url,
      timeout: 600000,
    };
    const instance = axios.create(config);
    //请求拦截
    instance.interceptors.request.use(
      async (config: any) => {
        const token = getToken();
        if (token) {
          config.headers["token"] = token;
        }
        return config;
      },
      (err: any) => {
        console.log(err);
      }
    );
    //响应拦截
    instance.interceptors.response.use(
      (config: any) => {
        if (config.status === 200) {
          if (config.data.code === 200) {
            return config;
          } else if (config.data.code === 10201 || config.data.code === 10203) {
            useStore.getState().logout(true);
            if (!notificationDisplayed) {
              notificationDisplayed = true;
              if (window.location.pathname !== "/login") {
                notification.error({
                  message:
                    "Not logged in or the login has expired, please log in again.",
                });
              }
            }
            return new Promise(() => {});
          } else if (config.data.code === 10109) {
            if (!notificationDisplayed) {
              notificationDisplayed = true;
              if (window.location.pathname !== "/login") {
                useMessageServiceStore.getState().confirm(
                  "This account has been logged in on another device.",
                  () => {
                    useStore.getState().logout(true);
                  },
                  {
                    closable: false,
                    okText: "Re-login",
                  }
                );
              }
            }
            return new Promise(() => {});
          } else if (config.data.code === 10111) {
            useStore.getState().logout(true);
            if (!notificationDisplayed) {
              notificationDisplayed = true;
              if (window.location.pathname !== "/login") {
                notification.error({
                  message: config.data.msg,
                });
              }
            }
            return new Promise(() => {});
          }
          return config;
        }
      },
      (err: any) => {
        throw err;
      }
    );

    instance(option)
      .then((res: any) => {
        if (option.responseType === "blob") {
          if (res.status === 200) {
            resolve(res.data);
          }
          return;
        }
        if (res.status === 200) {
          const rep = resolveReq(res);
          if (pass) {
            resolve(res.data);
            return;
          }
          if (rep.code === 200) {
            resolve(rep.data);
          }
        }
      })
      .catch((err: any) => {
        if (err.response) {
          const rep = resolveReq(err.response);
          if (rep.success) {
            resolve(rep.data);
          } else {
            // reject(err);
          }
        }
      })
      .finally(() => {
        // reject(null);
      });
  }) as any;
};

export default reqService;
