import { Button, Layout } from "antd";
import { FC, useState } from "react";
import "./index.less";
import Template from "@/common/template";
import { IOperationConfig } from "@/common/template/interface";
import useEventId from "@/hooks/useEventId";
import { useNavigate } from "react-router-dom";
import useMessageServiceStore from "@/store/messageServiceStore";
import "./index.less";
import { notification } from "antd";
import venuesAPI from "@/api/apis/venues";
import { IColumnsTypeP } from "@/common/interface";
import { Tag } from "antd";
import VebuesModal from "./VebuesModal";
const VenuesConfig: FC = () => {
  const templateId = useEventId();
  const navigate = useNavigate();
  const [oepn, switchOpen] = useState(false);
  const [venueId, setVenueId] = useState();
  const [key, setKey] = useState(1);
  const [notificationApi, contextHolder] = notification.useNotification();
  const { confirm, reloadList } = useMessageServiceStore();
  const queryData = (data: any) => {
    return venuesAPI.venuesPage(data);
  };
  const optList: IOperationConfig = [
    {
      text: "Modify",
      icon: "icon-a-Group3612",
      event: (data) => {
        navigate(`/admin/config/venues/${data.id}/edit`, {
          state: data,
        });
      },
    },
    {
      text: "Delete",
      backgroundColor: "warning",
      icon: "icon-a-Group3613",
      event: ({ name, id }) => {
        confirm(`Are you sure you want to delete the Venue [${name}]?`, () => {
          venuesAPI
            .venuesDelete({ id })
            .then(() => {
              notificationApi.success({ message: "Delete successfully" });
              reloadList(templateId);
            })
            .catch(console.error);
        });
      },
    },
  ];
  const columns: IColumnsTypeP<UserIDataItem>[] = [
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      render: (name: string, rowData: any) => {
        return (
          <Button
            type="link"
            onClick={() => {
              switchOpen(true);
              setVenueId(rowData.id);
              setKey((dev) => rowData.id + dev + 1);
            }}
          >
            {name}
          </Button>
        );
      },
    },
    {
      key: "flv_url",
      title: "Flv Url",
      dataIndex: "flv_url",
    },
    {
      key: "is_enabled",
      title: "Enable",
      dataIndex: "is_enabled",
      width: 120,
      render: (flag: 0 | 1) => {
        switch (flag) {
          case 1:
            return (
              <Tag color="#87d068" style={{ width: 60, textAlign: "center" }}>
                Enable
              </Tag>
            );
          case 0:
            return (
              <Tag color="#f50" style={{ width: 60, textAlign: "center" }}>
                Disable
              </Tag>
            );
          default:
            return "-";
        }
      },
    },
  ];
  return (
    <Layout>
      {contextHolder}
      <Template
        id={templateId}
        title={"Venue Configuration"}
        optList={optList}
        queryData={queryData}
        search
        searchKeyWord={"name"}
        rowKey={"id"}
        config={columns}
        create={() => {
          navigate("/admin/config/venues/new");
        }}
      />
      {venueId && (
        <VebuesModal
          key={key}
          open={oepn}
          switchOpen={switchOpen}
          venueId={venueId}
        />
      )}
    </Layout>
  );
};

export default VenuesConfig;
interface UserIDataItem {
  name: string;
  flv_url: string;
  is_enabled: 0 | 1;
}
