import { Form, Input, Layout, notification } from "antd";
import { FC, useEffect, useCallback } from "react";
import "./index.less";
import FormS from "@/common/form/FormS";
import useMessageServiceStore from "@/store/messageServiceStore";
import { useLocation, useNavigate } from "react-router-dom";
import useEventId from "@/hooks/useEventId";
// import { mapTreeNodeSelect, mapTreeNodeSelectIds } from "@/common/utils/untils";
// import roleAPI from "@/api/apis/role";
// import CustomRadio from "@/components/customRadio";
import moduleAPI from "@/api/apis/module";
import BreadcrumbPlx from "@/common/breadcrumbPlx";
const NewRores: FC = () => {
  const navigate = useNavigate();
  const formId = useEventId();
  const { formUpdate } = useMessageServiceStore();
  const location = useLocation();
  const loadingData = useCallback(async () => {
    const data: any = location.state;
    if (data) {
      const _data = {
        ...data,
        mode: "edit",
      };
      formUpdate(formId, _data);
    }
  }, [location, formId, formUpdate]);

  useEffect(() => {
    loadingData();
  }, [loadingData]);
  const handelSubmitEvent = useCallback(
    async (data: any) => {
      const _data = { ...data };
      try {
        if (_data["mode"] === "edit") {
          delete _data.mode;
          await moduleAPI.moduleUpdate(_data);
          notification.success({
            message: "Update success",
          });
        } else {
          await moduleAPI.moduleAdd(_data);
          notification.success({
            message: "Add success",
          });
        }
        navigate("/admin/config/module");
      } catch (error) {
        console.error(error);
      }
    },
    [navigate]
  );

  return (
    <Layout>
      <BreadcrumbPlx items={["Permission", location.state ? "update" : "new"]} />
      <FormS
        id={formId}
        footerBtnConfig={{ okText: location.state ? "Edit" : "Add" }}
        submitEvent={handelSubmitEvent}
        layout="vertical"
      >
        <main className="page-container user-new-main">
          <div style={{ width: 500 }}>
            <Form.Item name="name" label="Name">
              <Input />
            </Form.Item>
            <Form.Item name="code" label="Code">
              <Input />
            </Form.Item>
            <Form.Item name="description" label="Description">
              <Input.TextArea />
            </Form.Item>
            <Form.Item name="auditable_type" initialValue={"roles"} hidden>
              <Input.TextArea />
            </Form.Item>
            {/* <Form.Item name="route_address" label="Route address">
              <Input />
            </Form.Item>
            <Form.Item name="icon" label="Icon">
              <Input />
            </Form.Item>
            <Form.Item name="sort" label="Sort">
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item name="parent_id" label="Parent">
              <TreeSelect
                showSearch
                style={{ width: "100%" }}
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                allowClear
                treeDefaultExpandAll
                treeData={ItreeData}
              />
            </Form.Item>
            <Form.Item initialValue={true} name="is_child">
              <CustomRadio text={"Is Child"} true={true} false={false} />
            </Form.Item> */}
          </div>
        </main>
      </FormS>
    </Layout>
  );
};

export default NewRores;
// "name": "test",
// "uri": "8080",
// "icon": "8080",
// "parent_id": 1,
// "is_child": false,
// "route_address": "/install",
// "sort":1,
// "label":"xxx"
