import { FC, useMemo } from "react";
import "./statusSelect.less";
import { Dropdown, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { transformOptionsValueWeb } from "@/common/utils/untilsX";
import { ISelectItem } from "@/common/interface";

type StatusId = "A" | "P" | "T" | "U" | "X";
interface IProps {
  text: StatusId;
  onChange?: (value: any) => void;
  disabled?: boolean;
}
const statusList: ISelectItem[] = [
  { id: "A", name: "Active" },
  { id: "P", name: "Pending" },
  { id: "T", name: "Terminate" },
  { id: "U", name: "Unsuccessful" },
  { id: "X", name: "Terminate now" },
];
const StatusSelect: FC<IProps> = ({ text, onChange, disabled }) => {
  const selectList = useMemo(() => {
    const commonStatusList: ISelectItem[] = statusList.filter(
      (item) => item.id !== "U"
    );
    const statusOptions: Record<StatusId, ISelectItem[]> = {
      A: commonStatusList,
      P: commonStatusList,
      T: commonStatusList,
      X: commonStatusList,
      U: statusList,
    };
    const _statusList = statusOptions[text] || [];
    return _statusList
      .filter((item) => item.id !== text)
      .map((item) => ({ key: item.id, label: item.name }));
  }, [text]);
  return (
    <Dropdown
      disabled={disabled}
      trigger={["click"]}
      placement="bottom"
      menu={{
        onClick: ({ key }) => onChange?.(key),
        items: selectList,
      }}
    >
      <div
        className="StatusSelect-box"
        style={{
          backgroundColor: selectColor(text),
          cursor: disabled ? "not-allowed" : "pointer",
        }}
      >
        <Space align="center">
          <span style={{ color: selectTextColor(text) }}>
            {transformOptionsValueWeb(statusList, text)}
          </span>
          <DownOutlined
            style={{ fontSize: 10, color: selectTextColor(text) }}
          />
        </Space>
      </div>
    </Dropdown>
  );
};

export default StatusSelect;

const selectColor = (status: StatusId) => {
  const colorMap: Record<StatusId, string> = {
    A: "#4caf50",
    P: "#ffeb3b",
    T: "#ea1c0d",
    U: "#ffeb3b",
    X: "#108ee9",
  };
  return colorMap[status];
};

const selectTextColor = (status: StatusId) => {
  const darkTextStatuses: StatusId[] = ["P", "U"];
  return darkTextStatuses.includes(status) ? "#313435" : "#fff";
};
