import { BaseAPI } from "../base/baseApi";

export class UserAPI extends BaseAPI {
  getUserPage(data: any) {
    return this.get("/user/list", data);
  }
  addUser(data: any) {
    return this.post("/user/add", data);
  }
  updatePage(data: any) {
    return this.post("/user/update", data);
  }
  login(data: any) {
    return this.post("/user/login", data, {}, true);
  }
  userDetail(token?: string) {
    return this.get(
      "/user/detail",
      {},
      {
        headers: {
          token,
        },
      }
    );
  }
  async userDelete(params: IparamsById) {
    return this.post(
      "/user/delete",
      {},
      {
        params,
      }
    );
  }
  //获取绑定QR code
  getUserTwoFactorAuthQrcode() {
    return this.get("/user/two-factor_auth/qrcode");
  }

  //二次验证绑定账号和验证接口
  userTwoFactorAuthVerify(
    params: IFetUserTwoFactorAuthVerifyParams,
    token?: string
  ) {
    return this.post(
      "/user/two-factor_auth/verify",
      {},
      { params, headers: { token } }
    );
  }

  //二次验证关闭
  userTwoFactorAuthDisable(params: ITwoFactorAuthDisableByIdParams) {
    return this.post("/user/two-factor_auth/disable", {}, { params });
  }
  // 退出登录
  userClearSession(params: IparamsById) {
    return this.post("/user/clear_session", {}, { params }, true);
  }
}

const userAPI = new UserAPI();
export default userAPI;
interface IparamsById {
  id: number;
}
interface IFetUserTwoFactorAuthVerifyParams {
  code: string;
}
interface ITwoFactorAuthDisableByIdParams {
  id: number;
}
// interface IFetUserTwoFactorAuthDisableParams {}
