import { ISearchPage } from "@/common/template/interface";
import { BaseAPI } from "../base/baseApi";

export class ConfigAPI extends BaseAPI {
  // 获取venues配置
  async getVenuesConfig() {
    return this.get("/config/venues", {}, {}, true);
  }
  // 修改venues配置
  async setVenuesConfig(data: ISetVenuesConfigParams) {
    return this.post("/config/venues/update", data, {}, true);
  }

  async configList(params: ISearchPage & any) {
    return this.get("/config/list", params);
  }

  async configCreate(data: IConfigAddAndUpadteParams) {
    return this.post("/config/add", data, {}, true);
  }

  async configUpdate(data: IConfigAddAndUpadteParams) {
    return this.post("/config/update", data);
  }

  async configDelete(params: IparamsById) {
    return this.post(
      "/config/delete",
      {},
      {
        params,
      }
    );
  }

  async getJobSendConfig() {
    return this.get("/config/job/send", {});
  }

  async getJobFetchConfig() {
    return this.get("/config/job/fetch", {});
  }
}

const configAPI = new ConfigAPI();
export default configAPI;

export interface ISetVenuesConfigParams {
  id: number;
  category: string;
  key: string;
  value: {
    hls: string;
    flv: string;
  };
}
interface IparamsById {
  id: number;
}

export interface IConfigAddAndUpadteParams {
  category: string;
  key: string;
  value: string;
}
