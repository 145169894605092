import { Layout } from "antd";
import { FC } from "react";
interface IPropsChildren {
  children: React.ReactNode;
}
const ContentP: FC<IPropsChildren> = ({ children }) => {
  return (
    <Layout.Content className="las-glb-content-container">
      {children}
    </Layout.Content>
  );
};

export default ContentP;
