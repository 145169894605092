import { FC, useCallback, useEffect } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Tooltip,
  notification,
} from "antd";
import { ICallback } from "@/common/interface";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import venuesAPI from "@/api/apis/venues";
import useMessageServiceStore from "@/store/messageServiceStore";
interface Iprops {
  open: boolean;
  switchOpen: ICallback<boolean>;
  venueId: string;
}
const VebuesModal: FC<Iprops> = ({ open, switchOpen, venueId }) => {
  const [notificationApi, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const { confirm } = useMessageServiceStore();
  const reset = useCallback(() => {
    switchOpen(false);
  }, [switchOpen]);
  const submit = useCallback(() => {
    form
      .validateFields()
      .then(async (data) => {
        const _values = data.values;
        const withUpdateData: any[] = _values.filter(
          (item: any) => item && "id" in item
        );
        const withoutCreateData: any[] = _values
          .filter((item: any) => item && !("id" in item))
          .map((item2: any) => ({ ...item2, venue_id: venueId }));
        if (withUpdateData?.length > 0 && withoutCreateData?.length > 0) {
          await Promise.all([
            venuesAPI.venuesUrlUpdateBatch(withUpdateData),
            venuesAPI.venuesUrlAddBatch(withoutCreateData),
          ]);
          notificationApi.success({ message: "Save successfully" });
        } else if (withUpdateData?.length > 0) {
          await venuesAPI.venuesUrlUpdateBatch(withUpdateData);
          notificationApi.success({ message: "Save successfully" });
        } else if (withoutCreateData?.length > 0) {
          await venuesAPI.venuesUrlAddBatch(withoutCreateData);
          notificationApi.success({ message: "Save successfully" });
        }
        switchOpen(false);
      })
      .catch(() => {});
  }, [form, notificationApi, venueId, switchOpen]);
  const getInitData = useCallback(
    async (venueId: string) => {
      const res = await venuesAPI.venuesUrlList({ venueId });
      if (Array.isArray(res)) {
        form.setFieldsValue({ values: res });
      }
    },
    [form]
  );
  useEffect(() => {
    getInitData(venueId);
  }, [getInitData, venueId]);
  const handelRemove = useCallback(
    (index: any, remove: any) => {
      const rowData = form.getFieldsValue().values[index];
      if (rowData && "id" in rowData) {
        confirm(
          `Are you sure you want to delete the flv_url [${rowData.name}]?`,
          () => {
            venuesAPI
              .venuesUrlDelete({ id: rowData.id })
              .then(() => {
                notificationApi.success({ message: "Delete successfully" });
                remove(index);
              })
              .catch((err) => {
                console.error(err);
              });
          }
        );
      } else {
        remove(index);
      }
    },
    [notificationApi, confirm, form]
  );

  return (
    <>
      {contextHolder}
      <Modal
        afterClose={reset}
        title="Flv url"
        open={open}
        width={1000}
        zIndex={999}
        onOk={submit}
        okText={"OK"}
        cancelText={"Cancel"}
        onCancel={() => {
          switchOpen(false);
        }}
      >
        <Form
          layout="horizontal"
          style={{ width: "100%" }}
          form={form}
          autoComplete="off"
        >
          <Form.List name="values">
            {(fields, { add, remove }) => (
              <>
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Flv url
                  </Button>
                </Form.Item>

                {fields.map(({ key, name, ...restField }) => (
                  <Row
                    gutter={20}
                    align={"middle"}
                    justify={"space-between"}
                    key={key}
                  >
                    <Col flex={1}>
                      <Form.Item
                        {...restField}
                        style={{ width: "100%" }}
                        label="name"
                        name={[name, "name"]}
                        rules={[
                          { required: true, message: "Name cannot be empty!" },
                        ]}
                      >
                        <Input style={{ width: "100%" }} placeholder="name" />
                      </Form.Item>
                    </Col>
                    <Col flex={1}>
                      <Form.Item
                        {...restField}
                        style={{ width: "100%" }}
                        label="Url"
                        name={[name, "flv_url"]}
                        rules={[
                          { required: true, message: "Url cannot be empty!" },
                        ]}
                      >
                        <Input style={{ width: "100%" }} placeholder="url" />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item>
                        <Tooltip title={"Remove"}>
                          <MinusCircleOutlined
                            style={{ fontSize: 30 }}
                            onClick={() => handelRemove(name, remove)}
                          />
                        </Tooltip>
                      </Form.Item>
                    </Col>
                  </Row>
                ))}
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </>
  );
};

export default VebuesModal;
