import excelAPI from "@/api/apis/excel";
import operateLogAPI from "@/api/apis/operateLog";
import Template from "@/common/template";
import { eventSelectData, filterEmptyProperties } from "@/common/utils/untils";
import { handleTimeFormat } from "@/common/utils/untilsX";
import { handleFormatValueNewValueAndOldValue } from "@/common/utils/untilsX";
import SelectorDataFromTo from "@/components/SelectorDataFromTo";
import TableHeaderQueryData from "@/components/TableHeaderQueryData";
import TableHeaderQueryDataByDatePicker from "@/components/TableHeaderQueryDataByDatePicker";
import TableHeaderQueryDataBySelect from "@/components/TableHeaderQueryDataBySelect";
import useEventId from "@/hooks/useEventId";
import { FC, SetStateAction, useCallback, useMemo, useState } from "react";
import BreadcrumbPlx from "@/common/breadcrumbPlx";
import { exportTableToPDF } from "./pdfHtml";

const AuditLogsUsersHistory: FC = () => {
  const [startTime, setStartTime] = useState<string>("");
  const [endTime, setEndTime] = useState<string>("");
  const [event, setEvent] = useState<string>("");
  const [targetUser, setTargetUser] = useState<string>("");
  const [oldVaues, setOldVaues] = useState<string>("");
  const [newVaues, setNewVaues] = useState<string>("");
  const [user, setUser] = useState<string>("");
  const [ipAddress, setIpAddress] = useState<string>("");
  const [userAgent, setUserAgent] = useState<string>("");
  const [logged, setLogged] = useState<string>("");
  const templateId = useEventId();
  const queryData = (data: any) => {
    const _data = {
      ...data,
    };
    return operateLogAPI.getAuditLogList(_data);
  };
  const change = useCallback(
    (e: {
      startTime: SetStateAction<string>;
      endTime: SetStateAction<string>;
    }) => {
      setStartTime(e.startTime);
      setEndTime(e.endTime);
    },
    []
  );

  const columns = [
    {
      key: "event",
      title: (
        <TableHeaderQueryDataBySelect
          data={eventSelectData}
          title="Event"
          getData={setEvent}
        />
      ),

      dataIndex: "event",
      width: 200,
    },
    {
      key: "tags",
      title: (
        <TableHeaderQueryData title="Target User" getData={setTargetUser} />
      ),
      dataIndex: "tags",
    },
    {
      key: "old_values",
      title: <TableHeaderQueryData title="Old Values" getData={setOldVaues} />,
      dataIndex: "old_values",
      render: handleFormatValueNewValueAndOldValue,
    },
    {
      key: "new_values",
      title: <TableHeaderQueryData title="New Values" getData={setNewVaues} />,
      dataIndex: "new_values",
      render: handleFormatValueNewValueAndOldValue,
    },
    {
      key: "username",
      title: <TableHeaderQueryData title="User" getData={setUser} />,
      dataIndex: "username",
    },
    {
      key: "ip_address",
      title: <TableHeaderQueryData title="IP Address" getData={setIpAddress} />,
      dataIndex: "ip_address",
    },
    {
      key: "user_agent",
      title: <TableHeaderQueryData title="User Agent" getData={setUserAgent} />,
      dataIndex: "user_agent",
    },
    {
      key: "created_at",
      title: (
        <TableHeaderQueryDataByDatePicker
          title="Date/Time Logged"
          getData={setLogged}
        />
      ),
      dataIndex: "created_at",
      render: (e: string) => handleTimeFormat(e, "YYYY-MM-DD HH:mm:ss"),
    },
  ];
  const queryParams = useMemo(() => {
    return filterEmptyProperties({
      event,
      target: targetUser,
      old_values: oldVaues,
      new_values: newVaues,
      username: user,
      ip_address: ipAddress,
      user_agent: userAgent,
      operate_date: logged,
      start_date: startTime,
      end_date: endTime,
      auditable_type: "users",
    });
  }, [
    event,
    targetUser,
    oldVaues,
    newVaues,
    user,
    ipAddress,
    userAgent,
    logged,
    startTime,
    endTime,
  ]);

  const exportEvent = useCallback((data: any) => {
    return excelAPI.operateLogExportExcel(data);
  }, []);
  const exportEventPdf = useCallback(async (data: any) => {
    const res = await operateLogAPI.getAuditLogList({
      ...data,
      operate_log: 1,
    });
    const timeStr = `${
      handleTimeFormat(data.start_date, "DD MMM YYYY", false) || res.min_time
    } - ${
      handleTimeFormat(data.end_date, "DD MMM YYYY", false) || res.max_time
    }`;
    return exportTableToPDF({ ...res, heading4: timeStr });
  }, []);

  return (
    <section>
      <div className="audit-logs-box">
        <BreadcrumbPlx items={["Audit Logs Users"]} />
        <SelectorDataFromTo onChange={change} />
        <div className="bg-white" style={{ marginTop: 15 }}>
          <Template
            id={templateId}
            title={""}
            exportConfig={{
              queryApi: exportEvent,
              fileName: "user_operation_logs",
              pdfQueryApi: exportEventPdf,
            }}
            queryData={queryData}
            rowKey={"id"}
            config={columns}
            scroll={{ x: 1600 }}
            // showFooter={false}
            moreParams={queryParams}
          />
        </div>
      </div>
    </section>
  );
};
export default AuditLogsUsersHistory;
