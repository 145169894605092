import {IRouteObject} from "@/common/interface";
import {filterRoutes} from "@/common/utils/untils";
import {parsePermissionTreeNodes} from "@/common/utils/untilsX";
import {commonRouter, routerList} from "@/router/menu";
import useStore from "@/store/store";
import {useEffect, useState} from "react";

const useMenu = () => {
  const [menu, setMenu] = useState<any>([]);
  const { userInfo } = useStore();

  useEffect(() => {
    let menuList: IRouteObject[] = [];
    if (userInfo && userInfo.two_factor_authentication !== 0) {
      const list = filterRoutes(routerList, userInfo.access_module_codes);
      // add commonRouter to the list 0
      list.unshift(commonRouter);
      menuList = parsePermissionTreeNodes(list);
    }

    setMenu(menuList);
  }, [userInfo]);

  return menu;
};

export default useMenu;
