import { DatePicker } from "antd";
import { FC, useRef } from "react";
import dayjs from "dayjs";
import { ICustomizeCompProps } from "@/common/interface";
import IconFont from "@/common/IconFont";
import "./datetimeitem.less";
// import { RangePickerProps } from "antd/es/date-picker";
interface IProps {
  disabledDate?: (currentDate: any) => boolean;
  disabledTime?: any;
}

const DateTimeItem: FC<ICustomizeCompProps & IProps> = (props) => {
  const { current: change } = useRef(props.onChange);
  return (
    <DatePicker
      style={{ width: "100%" }}
      placeholder=""
      value={props.value ? dayjs(props.value) : null}
      onChange={(e) => {
        change?.(dayjs(e).format("YYYY-MM-DD"));
      }}
      inputReadOnly={true}
      className="custom-datepicker"
      suffixIcon={
        <IconFont style={{ fontSize: 22 }} type="icon-calendar-alt" />
      }
      format="MM/DD/YYYY"
      // showTime={{
      //   format: "HH:mm:ss",
      // }}
      disabledDate={props.disabledDate}
      disabledTime={props.disabledTime}
    />
  );
};

export default DateTimeItem;
