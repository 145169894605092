import { FC } from "react";
import { Layout } from "antd";
import useStore from "@/store/store";
import "./index.less";
import dayjs from "dayjs";

const Welcome: FC = () => {
  const { userInfo } = useStore();
  return (
    <Layout className="home-page-layout" style={{ height: "100%" }}>
      <h2 className="home-title">Home</h2>
      <Layout.Content
        style={{ height: "calc(100% )", backgroundColor: "#FFF" }}
      >
        <section className="welcome-section">
          <p className="welcome-text">{`Welcome ${userInfo?.name}`}</p>
          <p className="welcome-text-sub">{`Today is ${dayjs().format(
            "ddd, MMMM D, YYYY"
          )}`}</p>
          {/* <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <em className="welcome-text">
              {dayjs().format("dddd, MMMM D, YYYY h:mm:ss A")}
            </em>
          </div> */}
        </section>
      </Layout.Content>
    </Layout>
  );
};

export default Welcome;
