import useFormP from "@/hooks/useFormP";
import useResourceData from "@/hooks/useResourceData";
import { Form, Input } from "antd";
import React, { FC, useCallback, useMemo, useState } from "react";
import { ICallback, IFormProps, ITrigger } from "@/common/interface";
import ModalP from "./modal";
import EInfoType from "@/store/infoType";
import useOpen from "@/hooks/useOpen";
import { IMap, IMapT } from "@/common/interface";
// import useMessageServiceStore from "@/store/messageServiceStore";
interface IProps {
  submitText?: string;
  onFieldsChange?: ICallback<any>;
  titleContentKey?: string;
  closeCb?: ITrigger;
  width?: number | string;
  children?: React.ReactNode;
  showFotterBtn?: boolean;
}
/**
 *  弹窗表单
 */
const FormP: FC<IFormProps & IProps> = (props) => {
  // const [isEdit, setEdit] = useState(false);
  const { showFotterBtn = true } = props;
  const [visible, changeVisible] = useOpen();
  const onFieldsChangeProps = props.onFieldsChange;
  // 合成提交事件
  const submitMemo = useMemo(() => {
    return (data: any, oldData?: any) =>
      props.submitEvent(
        propertyMerge(
          propertyReplace(data, props.propertyReplaceMap || {}, true),
          props.propertyMerge || {},
          true
        ),
        oldData
      );
  }, [props]);

  const [formData, submit] = useFormP(submitMemo);
  const ultimatelySubmit = useCallback(async () => {
    await formData
      .validateFields()
      .then(submit)
      .catch((err) => err);
  }, [formData, submit]);
  const open = useMemo(() => {
    return () => changeVisible();
  }, [changeVisible]);

  const create = useCallback(() => {
    formData.resetFields();
    // setEdit(false);
    changeVisible();
  }, [formData, changeVisible]);
  useResourceData(EInfoType.create_resource, props.id, create);

  const [currentData, setCurrentData] = useState<any>(null);
  const {
    titleContentKey,
    title: titleProps,
    propertyMerge: propertyMergeProps,
    propertyReplaceMap: propertyReplaceMapProps,
  } = props;

  const load = useCallback(
    (data: any) => {
      // setEdit(true);
      // 先合并属性
      let _data = propertyMerge(data, propertyMergeProps || {});
      // 再替换属性
      _data = propertyReplace(_data, propertyReplaceMapProps || {});
      formData.setFieldsValue({
        ..._data,
        mode: "edit",
        originData: JSON.stringify(_data),
      });
      open();
      if (onFieldsChangeProps) {
        onFieldsChangeProps(_data);
      }
      setCurrentData(_data);
    },
    [
      formData,
      open,
      propertyMergeProps,
      propertyReplaceMapProps,
      onFieldsChangeProps,
    ]
  );

  useResourceData(EInfoType.form_update, props.id, load);
  //载入单条数据
  useResourceData(EInfoType.once_update, props.id, (data) => {
    formData.setFieldsValue(data);
  });
  const titleValue = useMemo(() => {
    if (titleContentKey && currentData) {
      return currentData[titleContentKey];
    }
    return "";
  }, [titleContentKey, currentData]);

  const propsTitle = useMemo(() => {
    const title = titleProps;
    if (!titleValue) {
      return title;
    }
    return `${title} - ${titleValue}`;
  }, [titleValue, titleProps]);

  const title = propsTitle;

  // 附件按钮
  const submitBtns = useMemo(() => {
    if (props.submitBtns) {
      return props.submitBtns.map((btn) => ({
        ...btn,
        event() {
          const newValue = formData.getFieldsValue();
          delete newValue.originData;
          delete newValue.mode;
          btn.event(newValue);
        },
      }));
    }
    return [];
  }, [formData, props.submitBtns]);

  return (
    <ModalP
      title={title}
      width={props.width}
      visible={visible}
      showFotterBtn={showFotterBtn}
      onCancel={changeVisible}
      size="normal"
      onSubmit={ultimatelySubmit}
      submitBtns={submitBtns}
      afterClose={() => {
        formData.resetFields();
        setCurrentData(null);
        props.closeCb && props.closeCb();
      }}
    >
      <Form
        form={formData}
        initialValues={props.initialValues}
        layout={props.layout || "vertical"}
        autoComplete="off"
        onFinish={ultimatelySubmit}
        style={{ paddingRight: !props.layout ? 30 : 0 }}
        labelCol={
          !props.layout
            ? {
                style: { width: 310 },
              }
            : {}
        }
        wrapperCol={
          !props.layout
            ? {
                style: { width: "calc(100%)" },
              }
            : {}
        }
        onFieldsChange={(e: any[]) => {
          if (onFieldsChangeProps) {
            if (e.length > 0) {
              const obj = e[0];
              if (obj.name.length > 0) {
                const changeField = {
                  [obj.name.toString()]: obj.value,
                };
                /** 通知外层 */
                onFieldsChangeProps(changeField);
              }
            }
          }
        }}
      >
        {props.children}
        <Form.Item hidden name="mode">
          <Input />
        </Form.Item>
        <Form.Item hidden name="originData">
          <Input />
        </Form.Item>
      </Form>
    </ModalP>
  );
};

export default FormP;
/**
 * -修改对象的特定属性名
 * -反向转换时还原属性名
 * @param data 被转换的值
 * @param map 属性转换表
 * @param reverse 反向转换
 * @description 处理非法属性名
 */
export const propertyReplace = (data: any, map: IMap, reverse = false) => {
  let _data: any = { ...data };
  for (const key in map) {
    if (Object.prototype.hasOwnProperty.call(map, key)) {
      const _origin = reverse ? map[key] : key;
      const _target = reverse ? key : map[key];
      // 如果属性名和目标属性名不相同，需要交换
      if (_origin !== _target) {
        _data[_target] = _data[_origin];
        delete _data[_origin];
      }
    }
  }
  return _data;
};

/**
 * -将几个属性合并成一个新属性(删除原属性)
 * -反向转换时将属性值更新到原属性
 * @param data 原有对象
 * @param map 属性合并
 * @param reverse 反向转换
 * @description 为自定义组件新增属性
 * @notice 合并的属性名不能和原有属性名重合
 */
export const propertyMerge = (
  data: any,
  map: IMapT<string[]>,
  reverse = false
) => {
  let _data: any = { ...data };
  const needRemoveKey: any = {};
  for (const key in map) {
    if (!reverse) {
      const target: any = {};
      map[key].forEach((dataKey) => {
        target[dataKey] = data[dataKey];
        needRemoveKey[dataKey] = 1;
      });
      if (map[key].length > 0) {
        _data[key] = target;
      }
    } else {
      // 将属性值还原
      map[key].forEach((dataKey) => {
        _data[dataKey] = !!_data[key] ? _data[key][dataKey] : undefined;
      });
      if (map[key].length > 0) {
        needRemoveKey[key] = 1;
      }
    }
  }
  // 删除原有属性
  Object.keys(needRemoveKey).forEach((key) => {
    delete _data[key];
  });
  return _data;
};
