import { Layout, Tabs } from "antd";
import { FC, useCallback, useEffect, useState } from "react";
import "./index.less";
import venuesAPI from "@/api/apis/venues";
import GeoBlockingContent from "./content";
const GeoBlocking: FC = () => {
  const [venuesList, setVenuesList] = useState<any>([]);
  const getMeetingList = useCallback(async () => {
    const res = await venuesAPI.venuesList();
    if (res) {
      setVenuesList(res);
    }
  }, []);
  useEffect(() => {
    getMeetingList();
  }, [getMeetingList]);
  return (
    <Layout>
      <div className="audit-logs-users audit-logs-box">
        <h2 className="las-pages-title">
          Geo Block <span>management</span>
        </h2>
        <Tabs
          defaultActiveKey="1"
          type="card"
          tabBarGutter={5}
          tabBarStyle={{ marginBottom: 0, backgroundColor: "#F7F7F7" }}
          items={venuesList.map((item: { name: any; id: number }) => {
            return {
              label: item.name,
              key: item.id,
              children: <GeoBlockingContent id={item.id} />,
            };
          })}
        />
      </div>
    </Layout>
  );
};

export default GeoBlocking;
