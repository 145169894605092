import { ISearchPage } from "@/common/template/interface";
import { BaseAPI } from "../base/baseApi";

export class RoleAPI extends BaseAPI {
  async getRoleSelect() {
    return this.get("/role/select");
  }
  async rolePage(params: ISearchPage) {
    return this.get("/role/list", params);
  }
  async roleAdd(data: IroleAddParams) {
    return this.post("/role/add", data);
  }
  async roleUpdate(data: IroleAddParams & IParamsById) {
    return this.post("/role/update", data);
  }
  async moduleSelect() {
    return this.get("/module/select");
  }
  async roleModuleList(params: IrolePermissionListParams) {
    return this.get("/role/module/list", params);
  }
  async roleDelete(params: IParamsById) {
    return this.post(
      "/role/delete",
      {},
      {
        params,
      }
    );
  }
}

const roleAPI = new RoleAPI();
export default roleAPI;

interface IParamsById {
  id: number;
}

interface IroleAddParams {
  name: string;
  description: string;
  permissionIds: number[];
}

interface IrolePermissionListParams {
  roleId: number;
}
