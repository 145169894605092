import { XOR } from "ts-xor";
import { BaseAPI } from "../base/baseApi";
import { ISearchPage } from "@/common/template/interface";

export class SubscriberAPI extends BaseAPI {
  // 激活列表
  async getCustomerServiceValid(data: ICustomerServiceValidList) {
    return this.get("/subscriber/report/customer", data);
  }
  async getSubscriberList(params: IIGetSubscriberListParamsPlx) {
    return this.get("/subscriber/list", params);
  }
  async subscriberAdd(data: ISubscriberAddParams) {
    return this.post("/subscriber/add", data);
  }
  async subscriberUpdate(data: ISubscriberUpdate) {
    return this.post("/subscriber/update", data);
  }
  async subscriberUpdateTerminateReason(data: {
    id: number;
    termination_reason: string;
  }) {
    return this.post("/subscriber/update_terminate_reason", data);
  }
  // 终止列表
  async subscriberTerminated(data: IIGetSubscriberListParamsPlx) {
    return this.get("/subscriber/terminated", data);
  }

  async subscriberTerminate(data: {
    account_number: number;
    termination_reason: string;
    action: "terminate" | "terminate_now";
  }) {
    return this.post("/subscriber/terminate", data);
  }

  async subscriberPending(data: { account_number: number }) {
    return this.post("/subscriber/pending", data);
  }

  async subscriberActive(data: { account_number: number }) {
    return this.post("/subscriber/active", data);
  }
}
const subscriberAPI = new SubscriberAPI();
export default subscriberAPI;
interface IParamsById {
  id: number;
}

export interface ICustomerServiceValidList {
  page: number;
  pageSize: number;
  status: string; //A-active T-treminal
  start_date: string;
  end_date: string;
  termination_initiated_date: string;
  transaction_id: string;
  termination_initiated_by: string;
  termination_reason: string;
  package_name: string;
  amount: string;
  billing_cycle?: string;
  request_date: string;
  activation_date: string;
  /**
  page: number;
  pageSize: number;
  status: number;
  account_number?: string;
  request_date?: string;
  activation_date?: string;
  termination_date?: string;
  billing_cycle?: string;
  termination_initiated?: string;
  termination_by?: string;
   */
}

export interface IGetSubscriberListParams {
  status: 1 | 2 | 3; //1-pending 2-active 3-terminated
  account_number: string;
  start_date: string;
  request_date: string;
  activation_date: string;
  termination_date: string;
  billing_cycle: string;
  termination_initiated: string;
  termination_by: string;
  subcription_package: string;
  termination_by_name: string;
}

type IIGetSubscriberListParamsPlx = XOR<IGetSubscriberListParams, ISearchPage>;

interface ISubscriberAddParams {
  account_number?: number;
  subscription_rate_id?: string;
  status?: number;
}

type ISubscriberUpdate = XOR<ISubscriberAddParams, IParamsById>;
