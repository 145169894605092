import { FC, useCallback } from "react";
import "./index.less";
import Template from "@/common/template";
import useEventId from "@/hooks/useEventId";
import useMessageServiceStore from "@/store/messageServiceStore";
import { useNavigate } from "react-router-dom";
import { columns } from "./table";
import { IOperationConfig, ISearchPage } from "@/common/template/interface";
import configAPI from "@/api/apis/config";

const ConfigurationList: FC = () => {
  const templateId = useEventId();
  const navigate = useNavigate();
  const { confirm, reloadList } = useMessageServiceStore();
  const queryData = (data: ISearchPage) => {
    return configAPI.configList(data);
  };
  const deletConfig = useCallback(
    async ({ Id, Key }: { Id: number; Key: string }) => {
      try {
        confirm(`Are you sure you want to delete the  [${Key}]?`, async () => {
          await configAPI.configDelete({ id: Id });
          reloadList(templateId);
        });
      } catch (error) {
        console.error(error);
      }
    },
    [confirm, reloadList, templateId]
  );

  const optList: IOperationConfig = [
    {
      text: "Modify",
      icon: "icon-a-Group3612",
      event: (data) => {
        navigate(`/admin/config/config-management/${data.Id}/edit`, {
          state: data,
        });
      },
    },
    {
      text: "Delete",
      backgroundColor: "warning",
      icon: "icon-a-Group3613",
      event: ({ Key, Id }) => {
        deletConfig({ Id, Key });
      },
    },
  ];
  return (
    <section style={{ height: "100%" }}>
      <Template
        id={templateId}
        title={"Config"}
        queryData={queryData}
        search
        searchKeyWord="category"
        rowKey="id"
        optList={optList}
        config={columns}
        create={() => {
          navigate("/admin/config/config-management/new");
        }}
      />
    </section>
  );
};

export default ConfigurationList;
