
import { Select } from "antd";
import React, { FC, useMemo } from "react";
import { ICallback, ISelectItem, ISelectOptionConfig } from "../../interface";
interface IProps {
  data: ISelectItem[] | string[] | number[];
  size?: "small";
  onChange?: ICallback<any>;
  placeholder?: string;
  /** 空白选项 */
  emptyoption?: boolean;
  /** 页面级别缓存数据ID */
  listcacheid?: string;
  /** 下拉列表的展示和提交配置 */
  config?: ISelectOptionConfig;
  /** 默认值 */
  defaultValue?: any;
  /** 样式 */
  style?: React.CSSProperties;

  /** 禁用 */
  disabled?: boolean;
  /**开启多选 ,特殊情况 在开启多选的情况下emptyOption不生效*/
  multiple?: boolean;
  /** 开启翻译 */
  enableintl?: boolean;
  /** 支持清除 */
  allowClear?:boolean;
}

/**
 *
 * @param props
 */
const SelectP: FC<IProps & { value?: any }> = (props) => {
  const _resProps = props;
  const _resPropsData = useMemo(() => {
    const newProps = _resProps.multiple
      ? {
          ..._resProps,
          mode: "multiple" as "multiple",
        }
      : _resProps;
    const _newProps = {
      ...newProps,
      enableintl: _resProps.enableintl ? 1 : 0,
      emptyoption: _resProps.emptyoption ? 1 : 0,
    };
    return _newProps;
  }, [_resProps]);

  const idKey = useMemo(() => {
    if (props.config) {
      return props.config.idKey;
    }
    return "id";
  }, [props]);

  const textKey = useMemo(() => {
    if (props.config) {
      return props.config.textKey;
    }
    return "name";
  }, [props]);

  // 默认值
  let defaultValue;
  if (typeof props.defaultValue === "undefined") {
    if (_resProps.emptyoption && !_resProps.multiple) {
      defaultValue = "";
    }
  }
  const dataList: any = props.data;
  if (!Array.isArray(dataList)) {
    console.error("出现非数组下拉选项渲染");
  }
  return (
    <Select
      // mode
      getPopupContainer={(triggerNode) => triggerNode.parentElement}
      defaultValue={defaultValue}
      {..._resPropsData}
      showSearch={props.data.length > 8}
      optionFilterProp="label"
    >
      {_resProps.emptyoption && !_resProps.multiple && (
        <Select.Option value="" label="">
          -
        </Select.Option>
      )}
      {dataList.map((item: any) => {
        if (typeof item === "number" || typeof item === "string") {
          return (
            <Select.Option key={item} value={item} label={item}>
              {item}
            </Select.Option>
          );
        }
        // 特殊情况
        let text = 
          item[textKey]
        return (
          <Select.Option
            key={item[idKey]}
            value={item[idKey]}
            label={item[textKey]}
          >
            {text}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default SelectP;
