import { DataNode } from "antd/es/tree";
import {
  UserPermission,
  Auth,
  VenuesDataItem,
  IRouteObject,
} from "../interface";
// import CryptoJS from 'crypto-js';

export const judge = (actions: string[], perm: string[]) => {
  if (!perm || !perm.length) return false;

  if (perm.join("") === "*") return true;

  return actions.every((action) => perm.includes(action));
};
export const auth = (params: Auth, userPermission: UserPermission) => {
  const { resource, actions = [] } = params;
  if (resource instanceof RegExp) {
    const permKeys = Object.keys(userPermission);
    const matchPermissions = permKeys.filter((item) => item.match(resource));
    if (!matchPermissions.length) return false;

    return matchPermissions.every((key) => {
      const perm = userPermission[key];
      return judge(actions, perm);
    });
  }

  const perm = userPermission[resource];
  return judge(actions, perm);
};

export const xorCompare = (a: any, b: any) => {
  const _a = !!a ? 1 : 0;
  const _b = !!b ? 1 : 0;
  return _a ^ _b;
};

/**
 * 移除对象值为空的属性
 * @param a 一定不是空值
 */
export const removeEmptyObject = (a: any) => {
  if (!a) {
    return null;
  }
  if (typeof a !== "object") {
    return a;
  }
  let result: any = null;
  const keys = Object.keys(a);
  if (keys.length < 1) {
    return null;
  }
  keys.forEach((key) => {
    const value = removeEmptyObject(a[key]);
    if (value !== null) {
      if (!result) {
        result = {};
      }
      result[key] = value;
    }
  });
  return result;
};
/**
 * 比对两个对象，数组，简单属性
 * @description 判断a中的所有属性都在b中存在，并且相等
 * true 表示两者属性相等,
 * @param a
 * @param b
 * @param properties
 */
export const compareA_B: <T>(
  a: T,
  b: T,
  properties?: Array<keyof T>
) => boolean = <T>(a: T, b: T, properties?: Array<keyof T>) => {
  if (a === b) {
    return true;
  }
  if (typeof a !== "object") {
    return `${a}` === `${b}`;
  }
  // a, b 有一个为空值
  if (xorCompare(a, b)) {
    const onlyOne = removeEmptyObject(a || b);
    return !onlyOne;
  }
  if (!a && !b) {
    return true;
  }
  if (Array.isArray(a)) {
    if (!Array.isArray(b)) {
      return false;
    }
    if (a.length !== b.length) {
      return false;
    }
    return a.every((at) => b.some((bt) => compareA_B(at, bt)));
  }
  const aProperties = a ? (Object.keys(a!) as Array<keyof T>) : [];
  const bProperties = b ? (Object.keys(b!) as Array<keyof T>) : [];

  // 保证两者至少都有一个属性
  if (aProperties.length * bProperties.length < 1) {
    return false;
  }
  // 普通对象
  const compareProperties: Array<keyof T> =
    properties ||
    (aProperties.length < bProperties.length ? aProperties : bProperties);
  return compareProperties.every((key) => {
    if (a && b) {
      return compareA_B(a[key], b[key]);
    }
    return false;
  });
};
export const filterEmptyProperties = <T>(obj: T): Partial<T> => {
  const filteredObj: Partial<T> = {};
  if (!obj) {
    return {};
  }

  for (const key in obj) {
    if (Reflect.hasOwnProperty.call(obj, key)) {
      const value = obj[key as keyof T];

      // 检查属性是否为空
      if (value !== null && value !== undefined) {
        if (typeof value === "string" && value.trim() === "") {
          continue;
        } else if (
          typeof value === "object" &&
          Object.keys(value).length === 0
        ) {
          continue;
        }

        // 添加非空属性到新对象
        filteredObj[key as keyof T] = value;
      }
    }
  }

  return filteredObj;
};

// 递归将数据处理成TreeNode类型的数据
export const mapTreeNodeList = (
  data: any,
  config: {
    title: string;
    key: string;
    children: string;
  }
) => {
  try {
    if (data && data.length) {
      const treeData: DataNode[] = data.map((item: any) => {
        if (item[config.children] && item[config.children].length) {
          item.children = mapTreeNodeList(item[config.children], config);
        }
        item.title = item[config.title];
        item.key = item[config.key];
        item.children = item[config.children];
        return item;
      });
      return treeData;
    }
    return null;
  } catch {
    console.error("error");
    return null;
  }
};

// 递归将数据处理成TreeNode类型的数据
export const mapTreeNodeSelect = (data: any[]) => {
  const filteredTree: { value: any; title: any; children: any[] }[] = [];
  try {
    if (!data?.length) {
      return [];
    }
    data.forEach((item: any) => {
      filteredTree.push({
        value: item.code,
        title: item.name,
        children: mapTreeNodeSelect(item["children"]),
      });
    });
    return filteredTree;
  } catch {
    console.error("error");
    return [];
  }
};

export const mapTreeNodeSelectIds = (data: any[]) => {
  const filteredIds: number[] = [];
  try {
    if (!data?.length) {
      return [];
    }
    data.forEach((item: any) => {
      if (item["code"]) {
        filteredIds.push(item["code"]);
      }
      if (item["children"]) {
        mapTreeNodeSelect(item["children"]);
      }
    });
    return filteredIds;
  } catch {
    console.error("error");
    return [];
  }
};

export const processVenuesListData = (
  data: VenuesDataItem[]
): VenuesDataItem[] => {
  return data.map((item) => {
    const venuesList = item.venuesList.map((venue) => {
      if (item.used.includes(venue.value)) {
        return { ...venue, disabled: true };
      }
      return venue;
    });

    return { ...item, venuesList };
  });
};

export const eventSelectData = ["created", "updated", "deleted"];
export function hasCodeField(obj: any): boolean {
  return "code" in obj;
}
export const filterRoutes = (
  routes: IRouteObject[],
  accessModuleCodes: string[],
  two_factor_authentication?: 0 | 1
): IRouteObject[] => {
  if (!accessModuleCodes) {
    return [];
  }

  const accessCodes = accessModuleCodes.map((item: any) => item.code);

  const hasAccess = (code: string) => accessCodes.includes(code);
  const filterChildren = (children: IRouteObject[]): IRouteObject[] => {
    return children
      .map((route) => {
        if (hasAccess(route.code)) {
          return route;
        }
        return null;
      })
      .filter((route) => route !== null);
  };
  const filteredRoutes = routes
    .map((route) => {
      if (hasAccess(route.code) && route.code === "AUDITLOGS") {
        return route;
      }

      if (
        route.children?.length &&
        route.children.some((i: { code: string }) => i.code)
      ) {
        const filteredChildren = filterChildren(
          route.children.filter((i: { code: string }) => i.code)
        );

        if (filteredChildren.length > 0) {
          return { ...route, children: filteredChildren };
        }
      } else if (hasAccess(route.code)) {
        return route;
      }
      return null;
    })
    .filter((route) => route !== null);

  // accessCodes.forEach((code) => {
  //   filteredRoutes.forEach((route) => {
  //     if (
  //       route.children &&
  //       route.children.some((child: { code: string }) => child.code === code) &&
  //       !hasAccess(route.code)
  //     ) {
  //       if (!filteredRoutes.some((r) => r.code === route.code)) {
  //         filteredRoutes.push(route);
  //       }
  //     }
  //   });
  // });

  return filteredRoutes;
};

export const ensureFullDateTime = (dateStr: string, type: "start" | "end"): string => {
  const hasTimeRegex = /\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/;

  if (hasTimeRegex.test(dateStr)) {
    return dateStr;
  } else {
    return `${dateStr} ${type === "start" ? "00:00:00" : "23:59:59"}`;
  }
};


export const formatText = (str: string) => {
  return (
    str
      // 将下划线替换为空格
      .replace(/_/g, " ")
      // 将每个单词的首字母大写，其余字母小写
      .replace(/\b\w/g, (char) => char.toUpperCase())
  );
};


// AES 加密方法
// export const encryptPassword = (password: string, secretKey: string): string => {
//   // 使用 AES 加密
//   const ciphertext = CryptoJS.AES.encrypt(password, secretKey).toString();
//   return ciphertext;
// };

// // AES 解密方法（可选）
// export const decryptPassword = (ciphertext: string, secretKey: string): string => {
//   const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
//   const decrypted = bytes.toString(CryptoJS.enc.Utf8);
//   return decrypted;
// };
 