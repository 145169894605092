import React from "react";
import IconFont from "@/common/IconFont";
import EllipsisTooltip from "../template/components/ellipsisTooltip";
import { ISelectItem } from "../interface";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { oldValuesKeysAndNewValuesKeys } from "@/pages/auditLogsSubscriptions";
import { formatText } from "./untils";

export function parseMenus(routers: any, parentPaht?: string) {
  const _menus: any[] = [];
  try {
    routers.forEach((item: any) => {
      const { path, children = null, icon, label } = item;
      if (label) {
        _menus.push({
          key: `${parentPaht ? parentPaht : ""}/${path}`,
          icon: icon && <IconFont style={{ fontSize: 16 }} type={icon} />,
          label: `${label}`,
          children: children
            ? parseMenus(children)?.length && parseMenus(children, `/${path}`)
            : null,
        });
      }
    });
    return _menus;
  } catch (err) {
    console.log(err);
    return [];
  }
}

/** 表格省略号 */
export const ellopsisOnCell: any = () => {
  return {
    style: {
      whiteSpace: "nowrap",
      maxWidth: 150,
    },
  };
};

export const ellopsisRender = (text: React.ReactNode | string) => {
  return <EllipsisTooltip title={text}>{text}</EllipsisTooltip>;
};

export const transformOptionsValueWeb = (
  options: ISelectItem[] | string[],
  value: string | number
) => {
  const map: any = {};
  options.forEach((option: any) => {
    if (typeof option === "string") {
      map[option] = option;
    } else {
      map[`${option.id}`] = option.name;
    }
  });
  if (!value && value !== 0) {
    return "-";
  }
  if (typeof map[value] === "undefined") {
    console.error("出现未知选项", value);
    return value;
  }
  return map[`${value}`];
};

export function parsePermissionTreeNodes(
  routers: any[],
  parentPath: string = ""
) {
  const _menus: any[] = [];
  try {
    const newrouters = routers.filter((item) => item.label);
    newrouters.forEach((item: any) => {
      const { path, children = null, icon, label } = item;
      const fullPath = parentPath
        ? `${parentPath}${path ? "/" + path : ""}`
        : `/admin${path ? "/" + path : ""}`;
      if (label) {
        _menus.push({
          key: `${fullPath}`,
          icon: icon && <IconFont style={{ fontSize: 16 }} type={icon} />,
          label: `${label}`,
          children: children
            ? parsePermissionTreeNodes(children, fullPath)?.length &&
              parsePermissionTreeNodes(children, fullPath)
            : null,
        });
      }
    });
    return _menus;
  } catch (err) {
    console.error(err);
    return [];
  }
}

export function parsePermissionTreeNodesTiled(routers: any): string[] {
  const paths: string[] = [];

  function extractPaths(node: any) {
    if (node?.route_address) {
      paths.push(node.route_address);
    }
    if (node.child_tree_node_list) {
      for (const childNode of node.child_tree_node_list) {
        extractPaths(childNode);
      }
    }
  }

  try {
    for (const item of routers) {
      extractPaths(item);
    }
    return paths;
  } catch (err) {
    console.error(err);
    return [];
  }
}

export const handleFormatValueNewValueAndOldValue = (e: any) => {
  let keyValuePairs: any = "-";
  let keyValuePairs2: any = "-";
  const formatValue = (value: any) => {
    if (Array.isArray(value)) {
      return value.map((item, index) =>
        typeof item === "object" ? (
          <div key={index}>
            {Object.keys(item).map((subKey) => (
              <p style={{ marginLeft: 30 }} key={subKey}>
                <span style={{ fontWeight: 700 }}>{subKey}</span>:
                {`${item[subKey]}`}
              </p>
            ))}
          </div>
        ) : (
          <span key={index}>{item}</span>
        )
      );
    } else if (typeof value === "object" && value !== null) {
      return Object.keys(value).map((subKey) => (
        <p style={{ marginLeft: 30 }} key={subKey}>
          <span style={{ fontWeight: 700 }}>{subKey}</span>:{`${value[subKey]}`}
        </p>
      ));
    } else {
      return <span>{`${value}`}</span>;
    }
  };
  if (e) {
    const obj: any = JSON.parse(e);
    keyValuePairs = Object.keys(obj)?.map((key) => (
      <div key={key}>
        <p style={{ color: "#74798C" }}>
          <span style={{ fontWeight: 700 }}>{key}</span>:{" "}
          {formatValue(obj[key])}
        </p>
      </div>
    ));
    keyValuePairs2 = Object.keys(obj)?.map((key) => (
      <div key={key}>
        <p>
          <span style={{ fontWeight: 700 }}>{key}</span>:{" "}
          {formatValue(obj[key])}
        </p>
      </div>
    ));
  }
  return (
    <EllipsisTooltip title={keyValuePairs2}>{keyValuePairs}</EllipsisTooltip>
  );
};

export const handleTimeFormat = (time: string, format: string, ele = true) => {
  dayjs.extend(customParseFormat);

  if (!time || time.startsWith("0001-")) {
    return ele ? "-" : "";
  }
  return ele ? (
    <EllipsisTooltip title={dayjs(time).format(format)}>
      {dayjs(time).format(format)}
    </EllipsisTooltip>
  ) : (
    dayjs(time).format(format)
  );
};
export function isDefined(value: any): boolean {
  return (
    value !== undefined && value !== null && value !== "" && value !== false
  );
}

export const handleFormatValueNewValueAndOldValueSubscriptions = (e: any) => {
  let keyValuePairs: any = "-";
  if (e) {
    const obj: any = JSON.parse(e);
    // console.log(obj);

    keyValuePairs = oldValuesKeysAndNewValuesKeys.map((key: string, idx) => {
      if (key === "start_date" || key === "request_date") {
        return (
          isDefined(obj[key]) &&
          !obj[key].startsWith("0001-") && (
            <p>
              <span style={{ fontWeight: "bold" }}>
                {key === "start_date" ? "Activation Date" : formatText(key)}
              </span>
              : {handleTimeFormat(obj[key], "YYYY-MM-DD HH:mm:ss", false)}
            </p>
          )
        );
      } else if (key === "end_date") {
        return (
          isDefined(obj[key]) &&
          !obj[key].startsWith("0001-") && (
            <p>
              <span style={{ fontWeight: "bold" }}>{formatText(key)}</span>:{" "}
              {handleTimeFormat(obj[key], "YYYY-MM-DD", false)}
            </p>
          )
        );
      } else {
        return (
          isDefined(obj[key]) && (
            <p>
              <span style={{ fontWeight: "bold" }}>{formatText(key)}</span>:{" "}
              {obj[key]}
            </p>
          )
        );
      }
    });
    // keyValuePairs = (
    //   <div>
    //     {obj[key] && !obj?.start_date.startsWith("0001-") && (
    //       <p>
    //         <span style={{ fontWeight: "bold" }}>Activation Date</span>:{" "}
    //         {handleTimeFormat(obj?.start_date, "YYYY-MM-DD HH:mm:ss", false)}
    //       </p>
    //     )}
    //     {obj?.end_date && !obj?.end_date.startsWith("0001-") && (
    //       <p>
    //         <span style={{ fontWeight: "bold" }}>End Date</span>:{" "}
    //         {handleTimeFormat(obj?.end_date, "YYYY-MM-DD", false)}
    //       </p>
    //     )}
    //     {obj?.status && (
    //       <p>
    //         <span style={{ fontWeight: "bold" }}>Status</span>: {obj?.status}
    //       </p>
    //     )}
    //     {!obj?.start_date && !obj?.end_date && !obj?.status && "-"}
    //   </div>
    // );
  }
  return keyValuePairs;
};
