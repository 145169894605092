import { DatePicker } from "antd";
import dayjs from "dayjs";
import { FC, useCallback, useRef } from "react";
interface ITableHeaderQueryDataByDatePicker {
  title: string;
  getData: any;
}
const TableHeaderQueryDataByDatePicker: FC<
  ITableHeaderQueryDataByDatePicker
> = ({ title, getData }) => {
  const { current: change } = useRef(getData);
  const handleChage = useCallback(
    (e: any) => {
      if (change) {
        if (e) {
          const date = dayjs(e).format("YYYY-MM-DD");
          change?.(date);
        } else {
          change?.("");
        }
      }
    },
    [change]
  );

  return (
    <div style={{ width: "100%" }}>
      <p
        style={{
          color: "#9B9EAC",
          marginBottom: 8,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {title}
      </p>
      <DatePicker
        style={{ width: "100%" }}
        onChange={handleChage}
        placeholder={`Select ${title}`}
      />
    </div>
  );
};

export default TableHeaderQueryDataByDatePicker;
