import { Form, Input, Layout, notification, Space } from "antd";
import { FC, useCallback, useEffect, useState } from "react";
import "./index.less";
import FormS from "@/common/form/FormS";
import useEventId from "@/hooks/useEventId";
import useMessageServiceStore from "@/store/messageServiceStore";
import { useLocation, useNavigate } from "react-router-dom";
import CheckButtonP from "@/components/checkButton";
import venuesAPI from "@/api/apis/venues";
import BreadcrumbPlx from "@/common/breadcrumbPlx";

const NewUser: FC = () => {
  const formId = useEventId();
  const { formUpdate } = useMessageServiceStore();
  const navigate = useNavigate();
  const [hidePwd, setHidePwd] = useState<boolean>(false);
  const location = useLocation();
  useEffect(() => {
    const data: any = location.state;
    if (data) {
      const _data = {
        id: data.id,
        flv_url: data?.flv_url,
        name: data?.name,
        geoblocks: data?.geoblocks,
        is_enabled: data?.is_enabled,
        mode: "edit",
      };
      formUpdate(formId, _data);
      setHidePwd(true);
    }
  }, [location, formId, formUpdate]);

  const submit = useCallback(
    async (value: any) => {
      try {
        const data = { ...value };
        if (data?.mode === "edit") {
          delete data.mode;
          await venuesAPI.venuesUpdate(data);
          notification.success({
            message: "Update success",
          });
        } else {
          // 新增
          await venuesAPI.venuesAdd(data);
          notification.success({
            message: "Add success",
          });
        }
        navigate("/admin/config/venues");
      } catch (error) {
        console.error(error);
      }
    },
    [navigate]
  );
  return (
    <Layout>
      <BreadcrumbPlx items={["Venue", location.state ? "update" : "new"]} />
      <FormS
        id={formId}
        footerBtnConfig={{ okText: hidePwd ? "Edit" : "Add" }}
        submitEvent={submit}
        layout="vertical"
      >
        <main className="page-container user-new-main" style={{ padding: 25 }}>
          <div style={{ width: 500 }}>
            <Form.Item
              rules={[
                {
                  required: true,
                  type: "string",
                  message: "Name is required",
                },
              ]}
              name="name"
              label="Name"
            >
              <Input />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  type: "string",
                  message: "Flv Url is required",
                },
              ]}
              name="flv_url"
              label="Flv Url"
            >
              <Input />
            </Form.Item>
            <Form.Item name="geoblocks" label="Geo Blocks">
              <Input.TextArea />
            </Form.Item>
            <Form.Item>
              <Space size={50}>
                <span>Enable</span>
                <Form.Item name="is_enabled" noStyle initialValue={1}>
                  <CheckButtonP true={1} false={0} />
                </Form.Item>
              </Space>
            </Form.Item>
          </div>
        </main>
      </FormS>
    </Layout>
  );
};

export default NewUser;
