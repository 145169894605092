interface TableData {
  event: string;
  tags: string;
  old_values: string;
  new_values: string;
  username: string;
  ip_address: string;
  user_agent: string;
  created_at: string;
}

interface JsonData<T> {
  heading1: string;
  heading2: string;
  heading3: string;
  heading4: string;
  data: T[];
}

export const exportTableToPDF = <T extends TableData>(
  jsonData: JsonData<T>
) => {
  let table = `
    <div id="container" style="display: flex; align-items: center; flex-direction: column; color: #000000;">
        <h1 style="all: unset; font-size: 24px; font-weight: bold;">
        ${jsonData.heading1}
        </h1>
        <p style="all: unset; font-size: 16px; font-weight: 550; line-height: 24px;">
        ${jsonData.heading2}
        </p>
        <p style="all: unset; font-size: 16px; font-weight: 550; line-height: 24px;">
        ${jsonData.heading3}
        </p>
        <p style="all: unset; font-size: 16px; font-weight: 550; line-height: 24px; color: red;">
        ${jsonData.heading4}
        </p>
        <table style="width: 100%; margin-top: 20px; border-spacing: 0; table-layout: fixed;">
            <thead style="background-color: #2d3f56; color: #ffffff; font-size: 14px; line-height: 28px;">
                <tr>
                    <th style="padding: 8px; text-align: center; font-weight: normal; word-wrap: break-word; white-space: normal; vertical-align: top;">${"Event"}</th>
                    <th style="padding: 8px; text-align: center; font-weight: normal; word-wrap: break-word; white-space: normal; vertical-align: top;">${"Target User"}</th>
                    <th style="padding: 8px; text-align: center; font-weight: normal; word-wrap: break-word; white-space: normal; vertical-align: top;">${"Old Values"}</th>
                    <th style="padding: 8px; text-align: center; font-weight: normal; word-wrap: break-word; white-space: normal; vertical-align: top;">${"New Values"}</th>
                    <th style="padding: 8px; text-align: center; font-weight: normal; word-wrap: break-word; white-space: normal; vertical-align: top;">${"User"}</th>
                    <th style="padding: 8px; text-align: center; font-weight: normal; word-wrap: break-word; white-space: normal; vertical-align: top;">${"IP Address"}</th>
                    <th style="padding: 8px; text-align: center; font-weight: normal; word-wrap: break-word; white-space: normal; vertical-align: top;">${"User Agent"}</th>
                    <th style="padding: 8px; text-align: center; font-weight: normal; word-wrap: break-word; white-space: normal; vertical-align: top;">${"Date/Time Logged"}</th>
                </tr>
            </thead>
            <tbody>
            ${jsonData.data
              .map(
                (item, idx) => `
                <tr style="font-size: 12px; line-height: 28px; background-color: ${
                  idx % 2 === 0 ? "#f3f4f5" : "#ffffff"
                };page-break-inside: avoid;">
                    <td style="padding: 8px; text-align: left; word-wrap: break-word; white-space: normal; vertical-align: top;">
                    ${item.event}
                    </td>
                    <td style="padding: 8px; text-align: left; word-wrap: break-word; white-space: normal; vertical-align: top;">
                    ${item.tags}
                    </td>
                    <td style="padding: 8px; text-align: left; word-wrap: break-word; white-space: normal; vertical-align: top;">
                    ${item.old_values}
                    </td>
                    <td style="padding: 8px; text-align: left; word-wrap: break-word; white-space: normal; vertical-align: top;">
                    ${item.new_values}
                    </td>
                    <td style="padding: 8px; text-align: left; word-wrap: break-word; white-space: normal; vertical-align: top;">
                    ${item.username}
                    </td>
                    <td style="padding: 8px; text-align: left; word-wrap: break-word; white-space: normal; vertical-align: top;">
                    ${item.ip_address}
                    </td>
                    <td style="padding: 8px; text-align: left; word-wrap: break-word; white-space: normal; vertical-align: top;">
                    ${item.user_agent}
                    </td>
                    <td style="padding: 8px; text-align: left; word-wrap: break-word; white-space: normal; vertical-align: top;">
                    ${item.created_at}
                    </td>
                </tr>`
              )
              .join("")}
            </tbody>
        </table>
    </div>`;
  return table;
};
