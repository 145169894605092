import { Button, Col, Form, Input, Row } from "antd";
import { FC, useCallback, useEffect, useRef } from "react";
import "./index.less";
import { StoreValue } from "antd/es/form/interface";
import { RuleObject } from "antd/es/form";
import CustomRadio from "@/components/customRadio";
import { ICallback } from "@/common/interface";
import { isEmailRegex } from "@/common/regex";
import useStore from "@/store/store";
type FieldType = {
  username?: string;
  password?: string;
  remember?: string;
};
interface IProps {
  onFinish: ICallback<FieldType>;
  loading: boolean;
}
const LoginForm: FC<IProps> = ({ onFinish, loading }) => {
  const { current: _onFinish } = useRef(onFinish);
  const { rememberMe, userName } = useStore();
  const [form] = Form.useForm();
  const emailvalidator = useCallback((_: RuleObject, value: StoreValue) => {
    if (!value) {
      return Promise.resolve();
    }
    if (!value.includes("@")) {
      return Promise.reject(
        new Error(
          `Please include "@" in the email address. ${value} is missing "@"`
        )
      );
    }
    if (!isEmailRegex(value)) {
      return Promise.reject(new Error("Please enter a valid email address!"));
    }
    return Promise.resolve();
  }, []);

  useEffect(() => {
    if (rememberMe === 0) {
      form.setFieldsValue({
        remember: 0,
      });
    } else {
      form.setFieldsValue({
        remember: rememberMe,
        username: userName,
      });
    }
  }, [form, rememberMe, userName]);

  return (
    <div className="login-page-right">
      <div className="login-page-form">
        <h1>Login</h1>
        <div className="login-page-form-box">
          <Form
            form={form}
            initialValues={{ remember: false }}
            onFinish={_onFinish}
            autoComplete="off"
            layout="vertical"
          >
            <p className="login-form-lable">Email</p>
            <Form.Item<FieldType>
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
                {
                  validator: emailvalidator,
                },
              ]}
            >
              <Input style={{ height: 32 }} autoComplete="off" />
            </Form.Item>
            <p className="login-form-lable">Password</p>
            <Form.Item<FieldType>
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password autoComplete="off" style={{ height: 32 }} />
            </Form.Item>
            <Row justify={"space-between"}>
              <Col>
                <Form.Item name="remember">
                  <CustomRadio text="Remember me" true={1} false={0} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  <Button
                    loading={loading}
                    style={{ height: 35 }}
                    type="primary"
                    htmlType="submit"
                  >
                    Login
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
