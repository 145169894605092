import { FC, useCallback, useState } from "react";
import logo from "@/assets/logo.png";
import "./inex.less";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import dayjs from "dayjs";
import useStore from "@/store/store";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import userAPI from "@/api/apis/user";
import { setToken } from "@/store/token";

const AuthenticationManagement: FC = () => {
  const { logout } = useStore();
  const [opt, setOtp] = useState("");

  const navigate = useNavigate();
  const handleLogout = useCallback(() => {
    logout();
    navigate("/login");
  }, [logout, navigate]);
  const handelVerify = useCallback(async () => {
    const token = await userAPI.userTwoFactorAuthVerify({ code: opt });
    if (token) {
      await setToken(token);
      navigate("/admin");
    }
  }, [opt, navigate]);
  return (
    <section className="auth-2fa-page">
      <h1 className="auth-2fa-page-title">
        <img src={logo} alt="logo" />
      </h1>
      <div className="auth-2fa-page-content">
        <p className="auth-2fa-content-icon">
          <CheckCircleOutlined />
        </p>
        <p className="auth-2fa-content-text1">Login Successful!</p>
        <p className="auth-2fa-content-text2">Dual Authentication (2FA)</p>
        <div className="auth-2fa-content-otp">
          <OtpInput
            value={opt}
            onChange={setOtp}
            numInputs={6}
            inputStyle={{
              width: "40px",
              height: "40px",
              fontSize: "20px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              textAlign: "center",
              color: "#666",
              outline: "none",
            }}
            shouldAutoFocus={true}
            renderSeparator={<div style={{ width: 20 }}></div>}
            renderInput={(props) => <input {...props} />}
          />
        </div>

        <p className="auth-2fa-content-text3">
          A message containing a verification code has been sent to your Google
          Authenticator.
        </p>
        <p className="auth-2fa-content-text3">
          Please enter the 6-digit two-factor security code to continue
        </p>
        <div className="auth-2fa-content-btncontainer">
          <Space size={50}>
            <Button onClick={handleLogout} style={{ width: 100 }}>
              Cancel
            </Button>
            <Button
              disabled={opt?.length < 6}
              onClick={handelVerify}
              style={{ width: 100 }}
              type="primary"
            >
              Continue
            </Button>
          </Space>
        </div>
      </div>
      <p className="auth-2fa-page-footer">
        Copyright &copy; {dayjs(new Date()).format("YYYY")} las. All Rights
        Reserved.
      </p>
    </section>
  );
};
export default AuthenticationManagement;
