import { Form, Input, Layout, notification } from "antd";
import { FC, useEffect, useCallback } from "react";
import "./index.less";
import FormS from "@/common/form/FormS";
import useMessageServiceStore from "@/store/messageServiceStore";
import { useLocation, useNavigate } from "react-router-dom";
import useEventId from "@/hooks/useEventId";
import configAPI from "@/api/apis/config";
import BreadcrumbPlx from "@/common/breadcrumbPlx";
const ConfigurationList: FC = () => {
  const navigate = useNavigate();
  const formId = useEventId();
  const { formUpdate } = useMessageServiceStore();
  const location = useLocation();
  const loadingData = useCallback(async () => {
    const data: any = location.state;
    if (data) {
      const _data = {
        category: data.Category,
        id: data.Id,
        key: data.Key,
        value: data.Value,
        mode: "edit",
      };
      formUpdate(formId, _data);
    }
  }, [location, formId, formUpdate]);

  useEffect(() => {
    loadingData();
  }, [loadingData]);
  const handelSubmitEvent = useCallback(
    async (data: any) => {
      const _data = { ...data };
      try {
        if (_data["mode"] === "edit") {
          delete _data.mode;
          await configAPI.configUpdate(_data);
          notification.success({
            message: "Update success",
          });
        } else {
          await configAPI.configCreate(_data);
          notification.success({
            message: "Add success",
          });
        }
        navigate("/admin/config/config-management");
      } catch (error) {
        console.error(error);
      }
    },
    [navigate]
  );

  return (
    <Layout>
      <BreadcrumbPlx items={["Config", location.state ? "update" : "new"]} />
      <FormS
        id={formId}
        footerBtnConfig={{ okText: location.state ? "Edit" : "Add" }}
        submitEvent={handelSubmitEvent}
        layout="vertical"
      >
        <main className="page-container user-new-main">
          <div style={{ width: 500 }}>
            <Form.Item name="category" label="Category">
              <Input />
            </Form.Item>
            <Form.Item name="key" label="Key">
              <Input />
            </Form.Item>
            <Form.Item name="value" label="Value">
              <Input />
            </Form.Item>
          </div>
        </main>
      </FormS>
    </Layout>
  );
};

export default ConfigurationList;
