// import ModalP from "@/common/modal";
import useSubscribe from "@/hooks/useSubscribe";
import useOpen from "@/hooks/useOpen";
import { FC, useState, useCallback } from "react";
import { IConfirmInfo } from "./interface";
import { InfoCircleOutlined } from "@ant-design/icons";
import EInfoType from "@/store/infoType";
import { Modal } from "antd";

const Confirm: FC = () => {
  const [oepn, switchOpen] = useOpen();
  const [config, setConfig] = useState<IConfirmInfo>({
    text: "",
    event: () => {},
    closable: true,
  });

  const loadConfirm = useCallback(
    (zconfig: IConfirmInfo) => {
      setConfig(zconfig);
      setTimeout(switchOpen, 50);
    },
    [switchOpen]
  );

  const reset = useCallback(() => {
    setConfig({
      text: "",
      event: () => {},
    });
  }, []);
  const submit = useCallback(() => {
    config?.event();
    switchOpen();
  }, [config, switchOpen]);

  useSubscribe(EInfoType.confirm_normal, loadConfirm);

  return (
    <Modal
      afterClose={reset}
      title="Reminder"
      open={oepn}
      closable={config?.closable}
      width={config.width ? config.width : 500}
      zIndex={999}
      onOk={submit}
      okText={config.okText ? config.okText : "OK"}
      cancelText={"Cancel"}
      cancelButtonProps={{
        style: {
          display: config.closable ? 'inline-block' : "none",
        },
      }}
      onCancel={() => {
        config?.closable && switchOpen();
      }}
    >
      <div className="comp-global-dialog">
        <InfoCircleOutlined style={{ fontSize: 26, color: "#FA0000" }} />
        <p>{config.text}</p>
      </div>
    </Modal>
  );
};

export default Confirm;
