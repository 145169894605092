import { Input } from "antd";
import { FC, SetStateAction, useCallback, useRef, useState } from "react";
interface ITableHeaderQueryData {
  title: string;
  getData: any;
}
const TableHeaderQueryData: FC<ITableHeaderQueryData> = ({
  title,
  getData,
}) => {
  const [data, setData] = useState<string>("");
  const { current: change } = useRef(getData);
  const handleChange = useCallback(
    (e: { target: { value: SetStateAction<string> } }) => {
      setData(e.target.value);
    },
    []
  );
  const handlePressEnter = useCallback(() => {
    if (change) {
      change(data);
    }
  }, [change, data]);
  return (
    <>
      <div>
        <p
          style={{
            color: "#9B9EAC",
            marginBottom: 8,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {title}
        </p>
        <Input
          style={{ minWidth: 90 }}
          placeholder={`Search ${title}`}
          onChange={handleChange}
          onPressEnter={handlePressEnter}
          onBlur={handlePressEnter}
          allowClear
        />
      </div>
    </>
  );
};
export default TableHeaderQueryData;
