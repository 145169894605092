// import IntlDep from "@/hooks/intl/IntlDep";
import { Button, Modal } from "antd";
import React, { FC, useMemo } from "react";
import { ITrigger } from "@/common/interface";
import FooterP from "./footer";

interface IModal {
  size?: "small" | "normal" | "large" | "extra_large";
  title?: React.ReactNode | string;
  visible: boolean;
  onCancel: ITrigger;
  /** 没有submit函数表示隐藏foot */
  onSubmit?: ITrigger;
  okText?: string;
  zIndex?: number;
  afterClose?: ITrigger;
  destroyOnClose?: boolean;
  submitBtns?: any[];
  width?: number | string;
  children?: React.ReactNode;
  showFotterBtn?: boolean;
}

const ModalP: FC<IModal> = (props) => {
  const { destroyOnClose = true, size, showFotterBtn = true } = props;
  const width = useMemo(() => {
    if (props.width) {
      return props.width;
    }
    if (size === "large") {
      return 780;
    }
    if (size === "small") {
      return 528;
    }
    if (size === "extra_large") {
      return 800;
    }
    return 528;
  }, [size, props.width]);

  let btnsEl: React.ReactNode[] = [];
  if (props.submitBtns) {
    btnsEl = props.submitBtns.map((btn, idx) => {
      return (
        <Button key={idx} onClick={btn.event}>
          {btn.text}
        </Button>
      );
    });
  }
  return (
    <Modal
      title={props.title || ""}
      width={width}
      getContainer={false}
      open={props.visible}
      footer={
        showFotterBtn ? (
          <FooterP
            submit={props.onSubmit}
            cancel={props.onCancel}
            okText={props.okText}
          >
            {btnsEl}
          </FooterP>
        ) : null
      }
      onCancel={props.onCancel}
      maskClosable={false}
      zIndex={props.zIndex || 1000}
      destroyOnClose={destroyOnClose}
      afterClose={props.afterClose}
    >
      {props.children}
    </Modal>
  );
};

export default ModalP;
