import {
  Form,
  Input,
  Layout,
  Row,
  Space,
  TreeSelect,
  notification,
} from "antd";
import { FC, useEffect, useState, useCallback } from "react";
import "./index.less";
import FormS from "@/common/form/FormS";
import { useNavigate } from "react-router-dom";
import useEventId from "@/hooks/useEventId";
import { mapTreeNodeSelect } from "@/common/utils/untils";
import CustomeRadio from "@/components/customRadio";
import roleAPI from "@/api/apis/role";
import FormItem from "antd/es/form/FormItem";
import BreadcrumbPlx from "@/common/breadcrumbPlx";
const NewRores: FC = () => {
  const [ItreeData, setTreeData] = useState<any[]>([]);
  const [subOldModulestreeData, setOldSubModulestreeData] = useState<any[]>([]);
  const [newSubModulestreeData, setnewSubModulestreeData] = useState<any[]>([]);
  const [submitSubModulesData, setSubmitSubModulesData] = useState<any[]>([]);
  const navigate = useNavigate();
  const getTreeList = useCallback(async () => {
    try {
      const res = await roleAPI.moduleSelect();
      setTreeData(mapTreeNodeSelect(res?.Modules));
      setOldSubModulestreeData(res?.SubModules);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getTreeList();
  }, [getTreeList]);

  const formId = useEventId();

  const handelSubmitEvent = useCallback(
    async (data: any) => {
      const _data = { ...data, extend_module_codes: submitSubModulesData };
      try {
        await roleAPI.roleAdd(_data);
        notification.success({
          message: "Add success",
        });
        navigate("/admin/roles");
      } catch (error) {
        console.error(error);
      }
    },
    [navigate, submitSubModulesData]
  );
  const handleChangeSubModel = useCallback(
    (values: any) => {
      if (Array.isArray(values?.access_module_codes)) {
        setnewSubModulestreeData(
          subOldModulestreeData.filter((item) => {
            return values?.access_module_codes.find(
              (oldItem: { code: string }) => oldItem === item?.parent_code
            );
          })
        );
      }
    },
    [subOldModulestreeData]
  );
  const handleSubmitSubModulesData = useCallback(
    (e: boolean, code: string) => {
      if (e) {
        setSubmitSubModulesData((predata: any) => [
          ...predata,
          ...newSubModulestreeData.filter((item) => item?.code === code),
        ]);
      } else {
        setSubmitSubModulesData((predata: any) =>
          predata.filter((item: any) => item?.code !== code)
        );
      }
    },
    [newSubModulestreeData]
  );
  const handleReset = useCallback(() => {
    navigate("/admin/roles");
  }, [navigate]);

  return (
    <Layout>
        <BreadcrumbPlx items={["Roles", "new"]} />
      <FormS
        id={formId}
        resetCallback={handleReset}
        forceShowSubmit
        onFieldsChange={handleChangeSubModel}
        footerBtnConfig={{ okText: "Add" }}
        submitEvent={handelSubmitEvent}
        layout="vertical"
      >
        <main className="page-container user-new-main">
          <div style={{ width: 500 }}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Name cannot be empty!",
                },
              ]}
              name="name"
              label="Name"
            >
              <Input />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Description cannot be empty!",
                },
              ]}
              name="description"
              label="Description"
            >
              <Input.TextArea />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Permission cannot be empty!",
                },
              ]}
              name="access_module_codes"
            >
              <TreeSelect
                showSearch
                style={{ width: "100%" }}
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                allowClear
                multiple
                treeDefaultExpandAll
                treeData={ItreeData}
              />
            </Form.Item>
            {newSubModulestreeData.map((item) => (
              <Row key={item.code} style={{ marginBottom: 20 }}>
                <FormItem noStyle>
                  <Space size={50}>
                    <CustomeRadio
                      true={true}
                      false={false}
                      onChange={(e) => handleSubmitSubModulesData(e, item.code)}
                    />
                    <span>{item.name}</span>
                  </Space>
                </FormItem>
              </Row>
            ))}
          </div>
        </main>
      </FormS>
    </Layout>
  );
};

export default NewRores;
