import Template from "@/common/template";
import { IColumnsTypeP, IOperationConfig } from "@/common/template/interface";
import useEventId from "@/hooks/useEventId";
import { FC, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import "./index.less";
import subscriberAPI from "@/api/apis/subscriber";
import StatusSelect from "./components/statusSelect";
import dayjs from "dayjs";
import useMessageServiceStore from "@/store/messageServiceStore";
import { Empty, Input, notification, Tooltip } from "antd";
import useSubModules from "@/hooks/useSubModules";
import { EditOutlined, FileSearchOutlined } from "@ant-design/icons";
import FormP from "@/common/form/FormP";
import FormItem from "antd/es/form/FormItem";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { handleTimeFormat } from "@/common/utils/untilsX";
dayjs.extend(customParseFormat);
const Subscriber: FC = () => {
  const templateId = useEventId();
  const formId = useEventId();
  const unFormId = useEventId();
  const navigate = useNavigate();
  const subModulesList = useSubModules();
  const { reloadList, formUpdate, createEvent } = useMessageServiceStore();
  const queryData = (data: any) => {
    return subscriberAPI.getSubscriberList(data);
  };

  const optList: IOperationConfig = [
    {
      text: "Modify",
      icon: "icon-a-Group3612",
      event: (data) => {
        navigate(`/admin/subscribers/${data.id}/edit`, {
          state: data,
        });
      },
    },
    {
      text: "Note",
      icon: <EditOutlined style={{ color: "#ea1c0d", fontSize: 18 }} />,
      hide: (e: any) => e.status !== "T",
      type: "custom",
      event: (data) => {
        const _data = { ...data };
        _data.reason = _data.termination_reason;
        formUpdate(formId, _data);
      },
    },
    {
      text: "View Note",
      icon: <FileSearchOutlined style={{ color: "#87d068", fontSize: 18 }} />,
      hide: (e: any) => e.status !== "U",
      type: "custom",
      event: (data) => {
        const _data = { ...data };
        formUpdate(unFormId, _data);
      },
    },
  ];
  const changeStatus = useCallback(
    async (data: any) => {
      try {
        const { status, account_number, termination_reason } = data;
        const statusActions: { [key: string]: () => Promise<void> | void } = {
          T: () => formUpdate(formId, data),
          X: () =>
            subscriberAPI.subscriberTerminate({
              account_number,
              termination_reason,
              action: "terminate_now",
            }),
          P: () => subscriberAPI.subscriberPending({ account_number }),
          A: () => subscriberAPI.subscriberActive({ account_number }),
        };

        if (statusActions[status]) {
          await statusActions[status]();
          if (status !== "T") {
            reloadList(templateId);
          }
        }
      } catch (error) {
        throw error;
      }
    },
    [templateId, reloadList, formUpdate, formId]
  );

  const handleChangeReason = useCallback(
    async (values: any) => {
      await subscriberAPI.subscriberTerminate({
        account_number: values.account_number,
        termination_reason: values.termination_reason,
        action: "terminate",
      });
      createEvent(formId);
      reloadList(templateId);
      notification.success({
        message: "Update success",
      });
    },
    [templateId, createEvent, reloadList, formId]
  );
  const changStatus = useMemo(() => {
    if (Array.isArray(subModulesList) && subModulesList?.length > 0) {
      return !subModulesList.includes("change_status");
    } else {
      return true;
    }
  }, [subModulesList]);

  const columns: IColumnsTypeP<UserIDataItem>[] = [
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      render(text, rowData) {
        if (rowData.status === "T" || rowData.status === "U") {
          let tip = "";
          if (rowData.status === "T") {
            tip = rowData.termination_reason;
          }
          if (rowData.status === "U") {
            tip = rowData.unsuccessful_reason;
          }
          return (
            <Tooltip title={tip}>
              <>
                <StatusSelect
                  text={text}
                  disabled={changStatus}
                  onChange={(e) => {
                    const _data = {
                      ...rowData,
                      status: e,
                    };
                    if (text !== e) {
                      changeStatus(_data);
                    }
                  }}
                />
              </>
            </Tooltip>
          );
        }
        return (
          <>
            <StatusSelect
              text={text}
              disabled={changStatus}
              onChange={(e) => {
                const _data = {
                  status: e,
                  id: rowData?.id,
                  subscription_rate_id: rowData?.subscription_rate_id,
                  account_number: rowData?.account_number,
                };
                if (text !== e) {
                  changeStatus(_data);
                }
              }}
            />
          </>
        );
      },
    },

    {
      key: "account_number",
      title: "Account Number",
      dataIndex: "account_number",
    },
    {
      key: "request_date",
      title: "Request Date",
      dataIndex: "request_date",
      render: (e) => handleTimeFormat(e, "MMM DD, YYYY hh:mm A"),
    },
    {
      key: "start_date",
      title: "Activation Date",
      dataIndex: "start_date",
      render: (e) => handleTimeFormat(e, "MMM DD, YYYY"),
    },
    {
      key: "end_date",
      title: "End Date",
      dataIndex: "end_date",
      // render: (text) => text || "-",
      render: (e) => handleTimeFormat(e, "MMM DD, YYYY"),
    },
    {
      key: "billing_cycle",
      title: "Billing Cycle",
      dataIndex: "billing_cycle",
    },
    {
      key: "termination_initiated_date",
      title: "Termination Initiated",
      dataIndex: "termination_initiated_date",
      render: (e) => handleTimeFormat(e, "MMM DD, YYYY hh:mm A"),
    },
    {
      key: "termination_by_name",
      title: "Termination By",
      dataIndex: "termination_by_name",
    },
  ];

  return (
    <section style={{ height: "100%" }}>
      <Template
        id={templateId}
        title={"Subscriber"}
        subTitle="management"
        optList={optList}
        queryData={queryData}
        search
        searchKeyWord="keyword"
        rowKey={"id"}
        config={columns}
        create={() => {
          navigate("/admin/subscribers/new");
        }}
      />
      <FormP
        id={formId}
        width={600}
        titleContentKey="account_number"
        title={"Account Number"}
        submitEvent={handleChangeReason}
      >
        <FormItem
          rules={[
            { required: true, message: "The Termination Note cannot be empty!" },
          ]}
          name={"termination_reason"}
          label={"Termination Note："}
        >
          <Input></Input>
        </FormItem>
      </FormP>
      <FormP
        id={unFormId}
        width={600}
        title={"Unsuccessful Reason"}
        submitEvent={() => {}}
        showFotterBtn={false}
      >
        <FormItem name={"unsuccessful_reason"} noStyle>
          <ShowText></ShowText>
        </FormItem>
      </FormP>
    </section>
  );
};

export default Subscriber;
export interface UserIDataItem {
  account_number: string;
  billing_cycle: string;
  billing_reference: string;
  created_at: string;
  created_at_human: string;
  created_by: number;
  duration: string;
  email: string;
  end_date: string;
  fee: number;
  for_processing: number;
  id: number;
  invoice_mode: string;
  is_renewed: number;
  package_name: string;
  request_date: string;
  start_date: string;
  status: string;
  subscription_rate_id: number;
  termination_by_name: string;
  termination_initiated_by: number;
  termination_initiated_date_at_human: string;
  termination_reason: string;
  transaction_id: string;
  unsuccessful_reason: string;
  updated_at: string;
  updated_at_human: string;
  updated_by: number;
  termination_initiated_date: string;
}

const ShowText = ({ value }: { value?: string }) => {
  return value ? (
    <div>{value || "-"}</div>
  ) : (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  );
};
