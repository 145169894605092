import React, { useState } from "react";
import { Layout } from "antd";
import "./index.less";
import ContentP from "./content";
import HeaderPlx from "./header";
import Sider from "./slider";
import { Navigate, Outlet } from "react-router-dom";
import useUserInfo from "@/hooks/useUserInfo";
// import LoadingPlx from "../LoadingPlx/LoadingPlx";

const LayoutContainer: React.FC = () => {
  const { userInfo } = useUserInfo();
  const [collapsed, setCollapsed] = useState(false);
  if (!userInfo) {
    return <Navigate to={"/login"} replace={true} />;
  }
  return (
    <Layout style={{ height: "100%", width: "100%", minWidth: 780 }}>
      <>
        <Sider collapsed={collapsed} setCollapsed={setCollapsed} />
        <Layout style={{ backgroundColor: "#f9fafb", height: "100%" }}>
          <HeaderPlx collapsed={collapsed} setCollapsed={setCollapsed} />
          <ContentP>
            <Outlet />
          </ContentP>
        </Layout>
      </>
    </Layout>
  );
};

export default LayoutContainer;
