import { BaseAPI } from "../base/baseApi";

export class MeetingAPI extends BaseAPI {
  async addMeeting(data: AddParams) {
    return this.post("/meeting/add", data);
  }
  async mettingList(params?: meetingListParams) {
    return this.get("/meeting/list", params);
  }
  async updateMeeting(data: UpdateParams[]) {
    return this.post("/meeting/update", data);
  }
  async meetingGeoBlocking(data: meetingGeoBlockingParams) {
    return this.post("/meeting/geo-blocking", data);
  }
  async meetingSetVenues(params: meetingSetVenuesParams) {
    return this.post("/meeting/set-venues", params);
  }

  async meetingSwitchStatus(data: meetingSwitchStatusParams) {
    return this.post("/meeting/switch-status", data);
  }

  async meetingDelete(params: IparamsById) {
    return this.post(
      "/meeting/delete",
      {},
      {
        params,
      }
    );
  }
  async meetingMonitor() {
    return this.get("/meeting/monitor");
  }
  /** 来源列表 */
  async sourceList() {
    return this.get("/meeting/source/list");
  }
  /** 来源列表 */
  async sourceSwitch(data: ISourceSwitch[]) {
    return this.post("/meeting/source/switch", data);
  }
}

const meetingAPI = new MeetingAPI();
export default meetingAPI;

interface AddParams {
  name: string;
}

interface UpdateParams {
  id: number;
  name: string;
  is_live: 1 | 0; //0 disable 1  enable
  country_id: number;
  venue_id: number;
  geoblocks: string;
}

interface meetingGeoBlockingParams {
  id: number;
  flag: 1 | 2; // 1-所有地区可用 2-新加坡可用
}

interface meetingSetVenuesParams {
  id: number;
  flag: number; //1-venuse1 2-venuse2 3-venues3
}

interface meetingSwitchStatusParams {
  id: number;
  status: 1 | 2; //1 disable 2  enable
}

interface IparamsById {
  id: number;
}

interface ISourceSwitch {
  key: string;
  sort: number;
}

interface meetingListParams {
  status?: number;
}
